import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Christmassantarun() {
  document.title = 'Christmas Santa Run | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Christmas Santa Run</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/christmassantarun/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_christmassantarun.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/christmassantarun.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Christmas Santa Run</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>In this captivating Christmas game, go out on an amazing holiday adventure. Join Santa Claus as he
                    races over snowy vistas in his sleigh, gathering gifts and bringing cheer to all.<br /><br /> As you
                    make your way through hard stages filled with jolly obstacles, take in the charm of the season.
                    Christmas Santa Run 2023 is the ideal holiday game for gamers of all ages because to its fascinating
                    graphics and merry soundtrack. Get lost in the festive spirit of 2023 and enjoy a warm gaming experience
                    that perfectly portrays the season. <br /><br />With the game of Christmas 2023, download it right away
                    to make this Christmas one to remember.</p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Christmas Santa Run game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Holiday-themed running game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Santa Claus adventure game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Festive Christmas game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Endless runner game for Christmas</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Santa sleigh run game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Joyful holiday gaming</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Christmas season run game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Snowy obstacle course game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Holiday gift collecting game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Santa&#x27;s sleigh ride adventure</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Christmas-themed endless run</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Festive obstacle avoidance game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Running game for holiday cheer</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Santa&#x27;s gift delivery run</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Christmassantarun;

