import { Link } from "react-router-dom";

function Header() {
  const mediaQuery = window.matchMedia('(max-width: 991px)')
  const openModel = () => {
    document.getElementsByClassName('navbar')[0].style.right = '0';
  }
  const closeModel = (e) => {
    console.log(e);
    if(mediaQuery.matches){
      document.getElementsByClassName('navbar')[0].style.right = '-450px';
    } else {
      document.getElementsByClassName('navbar')[0].removeAttribute('style');
    }
  }
  mediaQuery.addEventListener("change", function() {
    closeModel();
  });

  return (
    <div>
      <script type='text/javascript'> 
        google_ad_client = "pub-3990886853793852";
        google_ad_host = "pub-2885846345242349"; 
        google_ad_width = 728; 
        google_ad_height = 90; 
        google_ad_type = "text_image"; 
        google_color_border = "FFFFFF"; 
        google_color_bg = "ffffff"; 
        google_color_link = "0000ff"; 
        google_color_text = "000000"; 
        google_color_url = "008000"; 
        google_page_url = "https://heylink.me/"; 
    </script>
    <script src='https://pagead2.googlesyndication.com/pagead/show_ads.js' type='text/javascript'></script>
      <header className="pb-3 pb-lg-5">
        <div className="bgGradiant navGradiant"></div>
        <div className="margTop">
          <div className="container">
            <div className="header d-flex flex-wrap align-items-center justify-content-center justify-content-md-center">
              <Link to="/">
                <img src="assets/Game/Logo.png" alt="omeGames Logo" className="img-fluid logo web d-none d-lg-block" />
                <img src="assets/Game/Logo-mobile.png" alt="omeGames Logo" className="img-fluid logo mobile d-block d-lg-none" />
              </Link>
              <nav className="ms-auto">
                <ul className="navbar">
                  <li className="Close" onClick={closeModel}><img src="assets/Game/close.png" alt="Close Icon" draggable="false"
                      className="img-fluid" /></li>
                  <li className="nav">
                    <div className="f-18 navItem "><Link to="/">HOME</Link></div>
                  </li>
                  <li className="nav">
                    <div className="f-18 navItem "><Link to="/developer">DEVELOPERS</Link></div>
                  </li>
                  {/* <li className="nav">
                    <div className="f-18 navItem "><Link to="/affiliate">AFFILIATES</Link></div>
                  </li> */}
                  <li className="nav">
                    <div className="f-18 navItem "><Link to="/contactus">CONTACT US</Link></div>
                  </li>
                  <li className="nav">
                    <div className="f-18 navItem "><Link to="/aboutus">ABOUT US</Link></div>
                  </li>
                  <li className="nav">
                    <div className="f-18 navItem "><Link to="/privacy-policy">PRIVACY-POLICY</Link></div>
                  </li>
                </ul>
              </nav>
              <div className="blackBG "></div>
              <div className="navMedia">
                <div className="d-inline-block">
                  <div className="navIcon cusBtn" onClick={openModel}><img src="assets/Game/menu.png" alt="Menu Icon" draggable="false"
                      className="img-fluid" /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <script type='text/javascript'> 
        google_ad_client = "pub-3990886853793852";
        google_ad_host = "pub-2885846345242349"; 
        google_ad_width = 728; 
        google_ad_height = 90; 
        google_ad_type = "text_image"; 
        google_color_border = "FFFFFF"; 
        google_color_bg = "ffffff"; 
        google_color_link = "0000ff"; 
        google_color_text = "000000"; 
        google_color_url = "008000"; 
        google_page_url = "https://heylink.me/"; 
      </script>
    <script src='https://pagead2.googlesyndication.com/pagead/show_ads.js' type='text/javascript'></script>
    </div>
  );
}

export default Header;
