import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Bottlegarbage() {
  document.title = 'Bottle Garbage | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Bottle Garbage</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/bottlegarbage/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_bottlegarbage.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/bottlegarbage.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Bottle Garbage</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>With &quot;Bottle Garbage,&quot; the ultimate bottle challenge game that puts a unique spin on
                    recycling, set out on an environmentally beneficial adventure of talent and accuracy! Take part in an
                    addicting gaming experience where your goal is to pass, flip, and toss bottles into corresponding trash
                    cans.<br /><br />As you try to flawlessly land bottles into bins, test your timing and hand-eye
                    coordination. The challenge rises with each level, providing an exhilarating test of your skill.
                    &quot;Bottle Garbage&quot; is more than simply a game; it&#x27;s a technique to promote ethical waste
                    management.<br /><br />Take part in an exciting and gratifying journey that will delight you for hours
                    while promoting environmental awareness. &quot;Bottle Garbage&quot; is a fun and engaging game that is
                    perfect for anyone trying to perfect the bottle flip technique or just for a little fun.<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Bottle Garbage game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Recycling challenge gameplay</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Garbage sorting game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Eco-friendly gaming experience</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Bottle disposal simulator</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Waste management game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Environmental awareness challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Sorting and recycling game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Eco-conscious gameplay</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Trash disposal simulation</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Waste reduction game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Green gaming adventure</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Sustainable garbage game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Recycling puzzle challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Trash sorting simulator</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Bottlegarbage;
