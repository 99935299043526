import Header from './includes/Header';

function AboutUs() {
  return (
    <>
      <div id="__next">
        <Header />
        {/* <section class="my-5">
          <div class="container">
            <div class="d-flex flex-column justify-content-center align-items-center"><img
                src="assets/Game/backgroundtitle.png" alt="Game" draggable="false" class="img-fluid" /></div>
          </div>
        </section> */}
      <section class="my-3 AboutTxt">
        <div class="container">
          <h5 class="HeadBG">Free Online Games - Play Now On omeGames</h5><br />
          <script type='text/javascript'> 
            google_ad_client = "pub-3990886853793852";
            google_ad_host = "pub-2885846345242349"; 
            google_ad_width = 728; 
            google_ad_height = 90; 
            google_ad_type = "text_image"; 
            google_color_border = "FFFFFF"; 
            google_color_bg = "ffffff"; 
            google_color_link = "0000ff"; 
            google_color_text = "000000"; 
            google_color_url = "008000"; 
            google_page_url = "https://heylink.me/"; 
          </script>
          <script src='https://pagead2.googlesyndication.com/pagead/show_ads.js' type='text/javascript'></script>
          <p>omeGames features the latest and best free online games. We offer instant play to all our games without
            downloads, login, popups or other distractions. You can enjoy playing fun games without interruptions from
            downloads or pop-ups. <br /><br />omeGames has the best free online games selection and offers the most fun
            experience to play alone or with friends.</p><br />
          <h5 class="HeadBG">About omeGames</h5><br />
          <p>omeGames has a team of 25 people working on our gaming platform. Our mission is simple - to create a
            browser-gaming platform that works seamlessly for users around the world, and rewards developers both big
            and small.<br /><br />Our games are playable on desktop, tablet and mobile so you can enjoy them at school,
            at home or on the road. Every month millions of gamers from all over the world play their favorite games on
            omeGames. Our goal is to create the ultimate online playground. Free and open to all.<br /><br />We&#x27;re
            a team of makers, techies, adventurers – and some gamers too. We’re kids of all ages, and love what we
            do.<br /><br />Just load up your favorite games instantly in your web browser and enjoy the
            experience.<br /><br />You can play our games on desktop mobile devices. That includes everything from
            desktop PCs, laptops, and Chromebooks, to the latest smartphones and tablets from Apple and Android.</p>
          <br />
        </div>
      </section>
      </div>
    </>
  );
}

export default AboutUs;
