import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Icecandy() {
  document.title = 'Ice Candy | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play Online - Ice Candy</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    href="games/icecandy/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_icecandy.png" alt="Game"
                    draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/icecandy.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Ice Candy</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>If you love Ice Cream games and match 3 games, this is the one for you. Tired of free match 3 games
                    that are not actually free? You won’t have to worry about that in Ice Cream Frozen Mania! Every level
                    has been tried and tested to be beatable without boosts. Of course it doesn&#x27;t hurt to use a few,
                    especially when you are able to earn gems free for every level you beat.<br /><br />This Free Ice Cream
                    match 3 game will provide you with lots of fun and some of the greatest challenges!<br /><br />Ice Candy
                    is one of the best free match 3 games offline you will find and it will have you blasting the yummy
                    icecream in new cool ways by swapping power ups together to get even more points. Match as many tasty
                    treats as possible to get bigger power up blasts including the rainbow Popsicle. As you work your way
                    past the first 100 levels you will notice that they get much harder and some will make you think before
                    you move.<br /><br />How to Play:<br /><br />Move the ice cream and popsicles around to match 3 or more
                    same color. Matching more gives you more points and also power ups. Use power ups strategically to
                    complete the level&#x27;s target objective.<br /><br />Quick Tips:<br /><br />- Match more than 3 ice
                    cream to blast them and score more points<br /><br />- Matching 4 or more ice cream fuses power
                    ups<br /><br />- Match 5 in an L shape to create an ice cream bomb<br /><br />- Match 5 in a straight
                    line and you get the most powerful power up<br /><br />- If you are stuck look for the flashing
                    icecream<br /><br />- Watching a video after you fail a level gives you an extra 3 moves or 15 seconds
                    of time.<br /><br />- Every level you pass gives you 1 gem and can be repeated multiple
                    times<br /><br />- The Broccoli Monster will take over the board if you don&#x27;t kill him
                    quickly<br /><br />- Break the frozen ice blocks so that you can keep moving pieces<br /><br />- Blast
                    and crush ice cream under the items you need to collect such as the ice cream sundae and the chocolate
                    syrup bottle<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH"> Icecream Match3</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Icecream Game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Icecream HTML5 Game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Play Ice cream Game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Ice Cream Online</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Icrecream 3</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Icecandy;

