import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Potfiller() {
  document.title = 'Pot Filler | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Pot Filler</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/potfiller/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_potfiller.png" alt="Game"
                    draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/potfiller.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Pot Filler</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Introducing the ultimate convenience in culinary innovation - the Pot Filler. Elevate your cooking
                    experience with this elegant and practical addition to your kitchen. <br /><br />Crafted with both style
                    and functionality in mind, the Pot Filler offers a seamless solution to the hassle of carrying heavy
                    pots to the stove. <br /><br />With its retractable arm and dual-joint maneuverability, filling large
                    pots is now a breeze, eliminating the need for strenuous lifting and reducing the risk of spills.
                    Designed to complement any kitchen aesthetic, its sleek finish and ergonomic handle ensure comfortable
                    use. From simmering soups to boiling pasta, the Pot Filler&#x27;s efficient design saves time and
                    effort, transforming your cooking area into a chef&#x27;s haven.<br /><br /> Embrace ease and efficiency
                    with this remarkable culinary companion. <br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Pot Filler game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Fill the pot challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Pot filling gameplay</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Precision pot filling</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Filling pots game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Cooking simulation game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Kitchen pot challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Cooking and filling pots</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Pot pouring game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Cooking accuracy game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Culinary pot filler</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Kitchen-themed challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Cooking skill practice</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Filling pots simulation</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Cooking and timing game</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Potfiller;

