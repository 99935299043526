import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Harvestveggie() {
  document.title = 'Harvest Veggie | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Harvest Veggie</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/harvestveggie/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_harvestveggie.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/harvestveggie.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Harvest Veggie</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Play Harvest Veggie Game and build a huge farming paradise! Raise cattle, plant crops, train horses,
                    and complete fun missions.<br /><br />Play this phenomenal game and create a breathtaking farm that
                    everyone will want to see.<br /><br />Explore the landscape and uncover locations that have lots of
                    benefits and bonuses to offer.<br /><br />Craft food items from your harvests and sell them in the
                    market for a high profit.<br /><br />FREE adventure about farming, hay, jungle, coco and holiday spa.
                    Rest on vacation, eat banana, invite adorable farm animals and farm friends to your cove paradise! This
                    day will farm paradise - time to new day on you farm! New farm game from creators of Farm Town - more
                    that 10 000 000 players!<br /><br />Entire tropical farm at your disposal:<br /><br />★ Grow a fresh
                    crop;<br /><br />★ Build your own hotel and invite guests;<br /><br />★ Tame the most amazing animals:
                    lions, zebras and panda bears<br /><br />★ Create your own Zoo. Invite lovely pet and pets on island and
                    make them happy;<br /><br />★ Build island airport, steamboat and invite tourists;<br /><br />★ Find old
                    ruins and artifacts;<br /><br />★ Prepare your villagers to unforgettable adventure!<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Harvest Veggie</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Farm Game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Big Harvest Veggie</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Farming Games</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Happy Farm</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Farming - free online games</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Harvestveggie;

