import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Romeoswiftracer() {
  document.title = 'Romeo Swift Racer | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Romeo Swift Racer</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/romeoswiftracer/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_romeoswiftracer.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/romeoswiftracer.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Romeo Swift Racer</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>With &quot;Romeo Swift Race,&quot; the ultimate racing game that will put your talents to the test on
                    the virtual courses, get ready for high-speed excitement! You&#x27;ll assume the role of Romeo Swift in
                    this gripping challenge, a fearless racer prepared to win the race of a lifetime.<br /><br />Experience
                    the rush of racing as you move through heart-pounding tracks while overcoming obstacles and twists and
                    turns. &quot;Romeo Swift Race&quot; is the ideal game for players of all ages since it combines speed,
                    strategy, and accuracy in a captivating way.<br /><br />Take on a racing game challenge that offers
                    countless hours of fun and exhilarating action. This game offers an intense and competitive gaming
                    experience whether you&#x27;re a casual player looking for a racing adventure or a serious player trying
                    to win every race.<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Romeo Swift Race game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Racing challenge gameplay</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">High-speed racing adventure</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Race of Romeo Swift</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Virtual track racing game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Adrenaline-pumping race</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Racing game excitement</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Speed and strategy challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Addictive racing adventure</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Racing champion simulator</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Heart-pounding race game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Dynamic track maneuvers</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">High-speed racing simulation</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Romeo Swift racing action</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Thrilling racing gameplay</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Romeoswiftracer;

