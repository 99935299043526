import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Dashingdonut() {
  document.title = 'Dashing Donut | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play Online - Dashing Donut</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    href="games/dashingdonut/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_dashingdonut.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/dashingdonut.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Dashing Donut</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Build crazy machines, whip up outrageous donuts and get rich in this new idle clicker
                    game.<br /><br />Manage your unusual kitchen in a culinary quest to be the richest in the
                    world.<br /><br />In this tasty time management game you are the owner of a cute little donut shop.
                    Deep-fry donuts, add glaze and decoration, and serve your customers the heavenly delicious treats as
                    fast as you can. Unlock new donuts and decoration items the more you progress and make sure to earn
                    enough cash to meet your daily goals. Bon appetit!<br /><br />BUILD WACKY MACHINES<br /><br />Build
                    various types of machines to create a variety of crazy sugar infused donuts. Upgrade your machines and
                    watch your production line move faster, earning you more money. Make maximum profit from organised
                    chaos.<br /><br />LEGENDARY CHEFS!<br /><br />Hire chefs and work them to the ground. Your chefs
                    continue to make you money, even when you close the game!<br /><br />OPEN NEW BRANCHES<br /><br />Build
                    a global empire! Expand and open new branches all over the world. Worldwide themes and designs available
                    for your unusual kitchen.<br /><br />BECOME SUPER-FAMOUS!<br /><br />Be Rich and Famous! The more
                    branches you own, the more fame you get. Take advantage of your fame to get important upgrades to
                    further improve your wealth.<br /><br />FREE GUMBALLS!<br /><br />Win magic gumballs that give your
                    business a huge boost.<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Donut Cake Games</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Heavenly Sweet Dashing Donut</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Dashing Donut Factory Game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Play Free Dashing Donut Games</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Cooking Dashing Donut</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Donut Sprinkle</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Dashingdonut;

