import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Christmasgift() {
  document.title = 'Christmas Gifts | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play Online - Christmas Gifts</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/christmasgift/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_christmasgift.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/christmasgift.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Christmas Gifts</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>This Snowy Christmas the team of grouchy Grinch is back in the town with the mission to destroy
                    Christmas celebrations. Santa’s factory is being taken over by the evil green monsters and its set on
                    fire. The city kids have been kidnapped and locked up in the factory so that no kid gets gift from Santa
                    Claus. The Grinch won’t let children celebrate the joy of Christmas.<br /><br />In this situation Santa
                    Claus is all ready to set free the kids and get his factory back. Help Santa rescue the innocent kids
                    from the factory. Suppress the fire in the factory, find keys to unlock doors. Fight with the grumpy
                    girnches with the candy stick, hit them hard. Show some super-duper moves and get your gifts back from
                    the enemies. Find and save the kids on time and let them enjoy the Snow and Christmas Eve with love, joy
                    and peace!<br /><br />Features:<br /><br />• Beautiful 3D Christmas theme!<br /><br />• Get Back the
                    Gifts from Grinches!<br /><br />• Amazing Music and Sound Effects!<br /><br />• Challenging Escape
                    Missions!<br /><br />• Super Moves!<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Santa Claus</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Santa Game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Mission Match3</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Santas Game Online</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Play Santa Game Online</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Christmas Game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Santa Clause 2022</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Santa Fans</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Chrismas 2022</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Christmasgift;
