import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Endlessninjarun() {
  document.title = 'End Less Ninja Run | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - End Less Ninja Run</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/endlessninjarun/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_endlessninjarun.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/endlessninjarun.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">End Less Ninja Run</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>With &quot;Endless Ninja Run,&quot; the best endless runner game that will keep you on the edge of your
                    seat, set out on an action-packed journey! You&#x27;ll develop your ninja skills while slicing through
                    an exciting, dynamic world full with challenges in this thrilling game.<br /><br />Your reflexes and
                    smart thinking will be put to the test as you run, leap, and slide your way through several stages.
                    &quot;Endless Ninja Run&quot; is the ideal game for players of all ages since it provides an addicting
                    mix of quick action and accuracy.<br /><br />Play a game of continuous running that will keep you
                    entertained and exhilarated for hours. This game offers a thrilling and immersive experience, regardless
                    of whether you&#x27;re a casual player looking for an entertaining adventure or a serious player
                    determined to beat every level.<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Endless Ninja Run game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Endless runner gameplay</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Running game adventure</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Ninja action challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Fast-paced running game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Dynamic endless run</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Reflex-based ninja game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Addictive runner challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Adrenaline-fueled ninja adventure</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Agility and precision game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">End game challenge 2023</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Non-stop running fun</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Action-packed endless run</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Reflexes and strategy game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Ninja adventure challenge</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Endlessninjarun;

