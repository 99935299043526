import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Eggbucketmaster() {
  document.title = 'Egg Bucket Master | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Egg Bucket Master</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/eggbucketmaster/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_eggbucketmaster.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/eggbucketmaster.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Egg Bucket Master</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>With &quot;Egg Bucket Master,&quot; the egg-citing game of 2023 that will put your skill and precision
                    to the test, enter the realm of delectable challenges. To demonstrate your proficiency of handling
                    fragile cargo, you must catch falling eggs in a bucket in this engaging bucket game.<br /><br />Enjoy
                    the captivating action as you manage the bucket&#x27;s movements and place it precisely to collect the
                    descending eggs. Every level introduces fresh challenges, such as varied egg sizes and quicker drops,
                    which keep you on your toes and improve your hand-eye coordination.<br /><br />&quot;Egg Bucket
                    Master&quot; is ideal for players of all ages since it provides a special balance of skill and fun. This
                    game offers amusement for both casual gamers looking for fun and competitive players wanting to dominate
                    the leaderboards.<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Egg-catching challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Precision egg game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Catch the egg gameplay</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Egg bucket challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Dexterity and timing game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Egg-catching simulator</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Skillful egg bucket game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Egg-catching fun</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Quick reflex egg game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Egg-catching adventure</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">High-score egg game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Timed egg-catching challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Egg bucket accuracy</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Fun egg-catching simulation</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Eggbucketmaster;

