import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Noobvspro() {
  document.title = 'Noob Vs Pro | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Noob Vs Pro</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/noobvspro/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_noobvspro.png" alt="Game"
                    draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/noobvspro.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Noob Vs Pro</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Play our &quot;Noob vs. Pro&quot; battle to take on the ultimate gaming challenge!<br /><br /> Immerse
                    yourself in an exhilarating encounter that contrasts the worlds of amateur and professional players.
                    <br /><br /> Experience the thrill of seeing novices (noobs) enter the gaming world and struggle to
                    complete tasks as they learn the ropes. Watch the impressive abilities of seasoned pros as they expertly
                    and deftly maneuver across the simulated environments.<br /><br /> Through commitment and effort,
                    players in our game can advance from beginners to pros in a dynamic and fascinating journey. &quot;Noob
                    vs. Pro&quot; guarantees an amazing journey whether you&#x27;re looking for a playful encounter or
                    hoping to improve your talents.<br /><br /> Be a part of this gaming phenomenon by joining right
                    away!<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Noob vs Pro</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Gaming challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Noob game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Pro game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Gamer showdown</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Skill evolution</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Beginner vs Expert</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Gaming expertise</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Virtual battleground</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Gaming journey</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Noob to Pro progression</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Gaming skills comparison</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Competitive gaming experience</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Gaming mastery</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Gaming skill development</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Noobvspro;

