import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Dashcolorswitch() {
  document.title = 'Dash Color Switch | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Dash Color Switch</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/dashcolorswitch/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_dashcolorswitch.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/dashcolorswitch.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Dash Color Switch</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Get ready for a thrilling dash game that will stretch your abilities to the absolute limit.<br /><br />
                    In the action-packed adventure Dash Color Switch, time and accuracy are your best allies. Your
                    objective? Follow a colorful path by quickly changing colors to match the impending obstacles.
                    <br /><br /> You&#x27;ll advance quickly with each successful switch, but one poorly timed action could
                    spell your doom. Engage in an exciting voyage of reflexes and strategy while being overcome by a burst
                    of color. Dash Color Switch offers a dynamic gaming experience that tests your agility and concentration
                    while promising limitless fun. Can you overcome the shifting difficulties and triumph? Enter Dash Color
                    Switch right away to spark your dash!<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Dash Color Switch game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Fast-paced dash challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Color-changing gameplay</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Reflex-testing adventure</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Switch and dash challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Color matching game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Precision dash gameplay</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Dynamic color switch game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Fast reflexes challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Color coordination game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Speed and strategy game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Dash obstacle course</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Color-based runner game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">High-speed switch challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Color switch runner adventure</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Dashcolorswitch;

