import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Colorjumpgame() {
  document.title = 'Color Jump Game | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Color Jump Game</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/colorjumpgame/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_colorjumpgame.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/colorjumpgame.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Color Jump Game</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Get ready for a colorful and exciting jump challenge that will have you jumping for joy. As you
                    maneuver a dynamic ball through a sequence of platforms in the game Color Jump, your timing and reflexes
                    will be put to the test.<br /><br /> The problem? To move forward, you must achieve an exact color match
                    between the platform and the ball. Each leap turns into an exhilarating precision dance that demands
                    your fast thinking and faultless execution.<br /><br />Discover a visually gorgeous universe that
                    changes as you pass each new level. Color Jump provides an immersive experience that keeps you engrossed
                    for hours because to its engaging gameplay and vivid color schemes.<br /><br /> Are you prepared to take
                    on the task and rule the perfect color match universe? Do it now!</p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Color Jump Game game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Vibrant jump challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Reflex-testing gameplay</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Colorful platformer adventure</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Perfect color match game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Dynamic jumping challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Color coordination game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Precision leap adventure</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Addictive color jumping</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Platform color matching</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Color-based reflex game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Colorful obstacle course</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Colorful jump adventure</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Color-themed challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Jump and match gameplay</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Colorjumpgame;

