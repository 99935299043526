import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Tapcounterchallenge() {
  document.title = 'Tap Counter Challenge | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Tap Counter Challenge</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/tapcounterchallenge/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_tapcounterchallenge.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/tapcounterchallenge.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Tap Counter Challenge</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Immerse yourself in the ultimate tap challenge&#x27;s addicting environment.<br /><br /> Every tap
                    counts in Tap Counter Challenge as you attempt to reach the highest count.<br /><br /> Tap your way
                    through thrilling levels as you put your speed, accuracy, and stamina to the test.<br /><br /> This game
                    will keep your fingers tapping and your mind engaged with its intuitive gameplay and challenging
                    challenges. Tap Counter Challenge is an engaging experience that&#x27;s simple to pick up yet difficult
                    to master, whether you&#x27;re playing against friends or trying to beat your personal best. Take on the
                    challenge and demonstrate your dexterity in the hottest tapping game of 2023. Start tapping right now
                    and enjoy the excitement!<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Tap Counter Challenge game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Precision tapping adventure</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Tap counting challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Speed and accuracy game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Dynamic tap gameplay</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Ultimate tap challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Endurance tapping adventure</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Tapping prowess game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Tap to beat your best</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Tap competition challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Finger tapping adventure</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">High-score tapping game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Reflexes and tapping challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Competitive tap game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Tapping mastery adventure</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Tapcounterchallenge;

