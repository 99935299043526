import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Darkknightrider() {
  document.title = 'Dark knight Rider | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Dark knight Rider</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/darkknightrider/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_darkknightrider.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/darkknightrider.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Dark knight Rider</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Immerse yourself in the thrilling world of Dark Knight Rider, an exhilarating gaming experience that
                    combines the allure of darkness with the valiant spirit of a knight.<br /><br /> This cutting-edge game
                    brings the dark and challenging adventures of a knight rider to life, offering players an unprecedented
                    challenge that pushes their skills to the limit.<br /><br /> Navigate through treacherous landscapes,
                    conquer obstacles, and engage in heart-pounding battles, all while embracing the enigmatic ambiance of
                    the dark. Dark Knight Rider stands apart in the realm of knight rider games, delivering an unprecedented
                    rider challenge that will keep you hooked for hours on end. Embark on a journey like no other and
                    embrace the darkness as you become the ultimate knight rider.</p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Dark Knight Rider game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Knight rider adventure</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Dark fantasy gameplay</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Action-packed knight game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Mysterious realm challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Sword-wielding knight rider</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Shadowy landscapes adventure</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Epic battles in darkness</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Brave knight gaming</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Valiant rider challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Dark knight action game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Mysterious fantasy world</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Knight rider combat</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Quest in the shadows</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Adventure through darkness</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Darkknightrider;

