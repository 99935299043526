import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Roadrace2d() {
  document.title = 'Crazy Racing | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play Online - Crazy Racing</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    href="games/roadrace2d/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_roadrace2d.png" alt="Game"
                    draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/roadrace2d.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Crazy racing</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Drive your way from rookie to pro. The roar of the engines, the gleam of the steel, the thrill of the
                    race! Play “Crazy Racing” and live the adrenaline rush of this exciting 3D racing game. Jump behind the
                    wheel and challenge your opponents in the competition of a lifetime!</p>
                  <p>Imagine what you can get when you drive your superb stunning car! The street, city, sky! Start and
                    accelerate! You are the one surfing in it! Start your own crazy chasing with the hot girls around! Here
                    we go! “Don’t hesitate to download this game and experience the city drift. Your adrenaline must be rise
                    to the highest when play this racing game. It’s really an amazing feel about speed passion and life
                    chasing!”—TechLive</p>
                  <h5> How to play: </h5>
                  <p>Tilt your phone to move and swerve through traffic Touch the screen to boost and speed even faster
                    Collect coins to unlock new cars</p>
                  <h5> Game Features:</h5>
                  <p>Perfect game design and the most stunning game experience. Real dynamic game feeling with extra fun.
                    Hot and exciting background music must impress you! Bright 3D panoramic racing view. Various game modes,
                    enjoying the crazy joy!</p><br /><br />
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Racing Games</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Car Racing</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Racer</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Crazy racing - Thop games</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Car Racing HTML5 Game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Online Car Race</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Play Car Race</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Car Race 2D</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Real Car Race</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Sport Car Race</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Roadrace2d;

