import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Game() {
  document.title = '2048 | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play Online - 2048</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    href="games/2048/2048-master/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_2048.png" alt="Game"
                    draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/2048.png" alt="Online Game" draggable="flase" class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">2048</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>2048 Original is the classic 2048 puzzle game. Slide the tiles and merge them to reach 2048
                    tile.<br /><br />Start with 2 and reach 16, 32, 128, 512, 1024 and finally 2048.<br /><br />If you like
                    number games and number puzzle games than play free 2048 number puzzle game! It is one of the best brain
                    teasers and brain games for adults. Enjoy different games, difficult games and fun games in one brain
                    game! 2048 puzzle game is a fun free game and you can play without internet and offline!<br /><br />-
                    Square and Rectangle board options.<br /><br />- Different square board sizes. The smallest is 3x3 and
                    the biggest is 8x8.<br /><br />- Different rectangle board sizes. The smallest is 3x5 and the biggest is
                    6x9.<br /><br />- Achievements and Leaderboard.<br /><br />- Continue the game after reaching
                    2048.<br /><br />- Addictive 2048 play.<br /><br />- Number games and number puzzle games.<br /><br />
                  </p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">2048</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Play 2048</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Play 2048 Online</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Number Puzzle Game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Play 2048 Game Online</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">2048 Number Puzzle Game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Play Puzzle Game 2048</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">2048 Number Games</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Number Games Online</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Game;
