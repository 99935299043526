import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Arrowpatching() {
  document.title = 'Angry Bubble | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Arrow Patching</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/arrowpatching/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_arrowpatching.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/arrowpatching.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Arrow Patching</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>With &quot;Arrow Patching,&quot; the ultimate arrow game that will put your archery abilities to the
                    test, set out on a voyage of accuracy and focus! You will assume the role of a skilled archer entrusted
                    with repairing targets with your accurately targeted arrows in this intriguing challenge.<br /><br />As
                    you precisely hit targets and repair arrows at predetermined spots, feel the rush of the bullseye. Every
                    level has different difficulties to overcome, necessitating pinpoint accuracy and steady hands.
                    You&#x27;ll be entertained for hours by &quot;Arrow Patching&quot; since it combines skill and strategy
                    in a special way.<br /><br />This game offers a dynamic and enjoyable experience that is ideal for all
                    players, whether you are an experienced archer or are new to the world of arrows.<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH"> Arrow Patching game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Precision arrow challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Archery gameplay</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Arrow repair game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Aim and patching challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Bow and arrow simulator</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Target fixing game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Archery accuracy challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Skillful arrow patching</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Arrow mending adventure</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Arrow accuracy simulation</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Arrow repair challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Bow and arrow skill game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Archery puzzle gameplay</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Target patching simulator</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Arrowpatching;
