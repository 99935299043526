import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Airbattlemission() {
  document.title = 'Air Battle Mission | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Air Battle Mission</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/airbattlemission/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_airbattlemission.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/airbattlemission.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Air Battle Mission</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Air Battle Mission is an intense and action-packed mobile game that puts players in the cockpit of a
                    fighter jet, challenging them to complete a variety of daring missions in the skies.<br /><br />In Air
                    Battle Mission, players must take on enemy forces, completing missions such as protecting friendly
                    troops, attacking enemy bases, and taking out key targets. Players have access to a range of weapons and
                    power-ups, which they can use strategically to take out their opponents.<br /><br />The game features
                    intuitive touch controls, allowing players to easily maneuver their fighter jet and engage in dogfights
                    with enemy planes. Players must also be strategic in their approach, using their weapons and maneuvers
                    to outsmart and defeat their opponents.<br /><br />Air Battle Mission is designed with stunning and
                    realistic graphics, creating a thrilling and immersive gameplay experience. With its intense action,
                    challenging missions, and exciting aerial battles, Air Battle Mission is a game that&#x27;s sure to get
                    your adrenaline pumping.<br /><br />Whether you&#x27;re a fan of military games or just looking for an
                    exciting and action-packed mobile game to play, Air Battle Mission is a game that&#x27;s sure to
                    deliver. With its intuitive controls, realistic graphics, and thrilling gameplay, it&#x27;s a game
                    that&#x27;s hard to put down.<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH"> Action-packed air combat game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Exciting aerial warfare game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Challenging airplane game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Addictive air battle game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Intense dogfight game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Kid-friendly airplane game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Family-friendly air combat game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Multi-level aerial mission game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Thrilling flying game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Realistic air battle simulator game. </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Airbattlemission;
