import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Pixelfiller() {
  document.title = 'Pixel Filler | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play Online - Pixel Filler</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/pixelfiller/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_pixelfiller.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/pixelfiller.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Pixel Filler</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Color by number to melt your stress away with art coloring games from a top developer! Discover more
                    than 15,000 FREE 2D and 3D artworks or create your own pixel art. Paint by number, relax and enjoy Pixel
                    Art coloring game!<br /><br />Designed by gaming experts and loved by players worldwide, Pixel Art
                    coloring games help you dive into a world of coloring meditation. Choose from a wide range of
                    fascinating artworks and paint by number while having fun!<br /><br />Whether you are coloring to reduce
                    stress or simply to relax, you’ll love coloring by number with this painting game.<br /><br />Why play
                    Pixel Art coloring games?<br /><br />✔ Coloring by number is simple. Browse pictures, then just tap a
                    color number, and start painting the image. You will always know what color to use and where while
                    playing Pixel Art coloring games.<br /><br />✔ More than 15,000 images to choose from. Color by number
                    mandala pictures, flowers, and tons of other topics. Our coloring pages range from easy to very detailed
                    and suit any taste and mood.<br /><br />✔ New pictures to paint by number every day. Discover new number
                    coloring images on a daily basis, and you’ll never run out of free pictures to color!<br /><br />✔ Meet
                    a brand new feature - Flower Garden! Color unique objects, build your own garden and get rewards. Dive
                    into a new meta world inside Pixel Art coloring book!<br /><br />✔ Paint by number unique images during
                    Seasonal Events! Color by number thematic pictures and get unique bonuses. Our images are specially
                    crafted for major seasons, holidays and festivals. Build your own collection of images from popular
                    coloring topics, such as Christmas, Halloween, Thanksgiving, and many more.<br /><br />✔ Pixelate your
                    own photos with Pixel Art Camera. Upload any picture, prepare it for pixel coloring by adjusting the
                    difficulty, and have fun! Color by number all your photos for free with our pixel art
                    maker!<br /><br />✔ 3D coloring games for adults. Paint by numbers of 3D objects ensures an extremely
                    fun coloring experience.<br /><br />✔ Share time-lapse videos with just one tap. Show everyone you’re
                    into painting games!<br /><br />✔ Use coloring boosters to help you finish detailed pictures. Pick Color
                    Splash to quickly fill areas with colors or Magic Wand to paint by number a range of cells of the same
                    color.<br /><br />Art games are a great way to relax and unwind. You are in total control: you choose
                    what to color by numbers, where to do it, and when to start or finish. There’s no time limit or
                    competition breathing down your neck. Just take your phone and enjoy coloring games. Play paint by
                    number games and relax anywhere, anytime!<br /><br />Our coloring book is a great art therapy sandbox to
                    use when you feel anxious and stressed. Pick colors, put them on a board, and see the shades and
                    gradients appear on your drawings. Release your inner artist by playing art games!<br /><br />Start
                    playing anti-stress coloring games and unleash your talented inner artist! Leave anxiety behind with the
                    Pixel Art coloring games!<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Pixel Game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Pixel - Ome Games</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Pixel HTML5 Game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Online Pixel Game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Fill Pixel </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Pixel Color Online</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Pixel Art Games Online</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Pixel Art Game</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Pixelfiller;

