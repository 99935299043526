import Header from './includes/Header';

function Developer() {
  return (
    <>
      <div id="__next">
        <Header />
        {/* <section class="my-3">
            <div class="container">
            <div class="d-flex flex-column justify-content-center align-items-center"><img
                src="assets/Game/backgroundtitle.png" alt="Game" draggable="false" class="img-fluid" /></div>
            </div>
        </section> */}
        <section class="my-5 AboutTxt">
            <div class="container">
            <h1 class="HeadBG">Developers - omeGames</h1><br />
            <script type='text/javascript'> 
              google_ad_client = "pub-3990886853793852";
              google_ad_host = "pub-2885846345242349"; 
              google_ad_width = 728; 
              google_ad_height = 90; 
              google_ad_type = "text_image"; 
              google_color_border = "FFFFFF"; 
              google_color_bg = "ffffff"; 
              google_color_link = "0000ff"; 
              google_color_text = "000000"; 
              google_color_url = "008000"; 
              google_page_url = "https://heylink.me/"; 
            </script>
            <script src='https://pagead2.googlesyndication.com/pagead/show_ads.js' type='text/javascript'></script>
            <p>omeGames is a professional game development studio focusing on games. We also offer services for games
                applications, as well as responsive games. The omeGames platform allows developers to upload their content
                directly within the website, publish it, track it, and even monetize it just like a normal website but with
                a huge difference. There is a good traffic on www.omegames.in where developer can get traffic easily and can
                monetize games. <br /><br /></p><br />
            <h1 class="HeadBG">Contact Us and Join Now</h1><br /><br />mailto:contact@omegames.in<br /><br />
            </div>
        </section>
      </div>
    </>
  );
}

export default Developer;
