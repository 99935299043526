import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Jellyworld() {
  document.title = 'Jelly World | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Jelly World</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/jellyworld/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_jellyworld.png" alt="Game"
                    draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/jellyworld.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Jelly World</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Jelly World is a fun and addictive mobile game where players navigate through a world made entirely out
                    of colorful and wobbly jelly. With its bright and colorful graphics, fun characters, and challenging
                    levels, Jelly World is a game that&#x27;s easy to pick up but difficult to put down.<br /><br />In Jelly
                    World, players control a cute jelly character as they navigate through a variety of different levels,
                    each with its own unique challenges and obstacles. The game features intuitive touch controls, allowing
                    players to easily move their character around the world and jump over obstacles.<br /><br />As players
                    progress through the levels, they must collect stars and other items to unlock new levels and
                    characters. They must also avoid obstacles like spikes and enemies that can harm their character and
                    cause them to lose a life.<br /><br />Jelly World is designed with colorful graphics and a fun
                    soundtrack, creating a playful and engaging gameplay experience. With its addictive gameplay,
                    challenging levels, and cute characters, it&#x27;s a game that&#x27;s sure to keep players entertained
                    and engaged for hours.<br /><br />Whether you&#x27;re a fan of platformer games or just looking for a
                    fun and addictive mobile game to play, Jelly World is a game that&#x27;s hard to resist. With its
                    charming visuals, challenging gameplay, and endless replayability, it&#x27;s a game that you won&#x27;t
                    want to put down.<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH"> Fun jelly game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Cute character game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Addictive puzzle game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Jelly-matching game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Colorful jelly game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Sweet treats game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Jelly blast game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Challenging jelly puzzle game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Kid-friendly jelly game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Fun adventure game in jelly world. </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Jellyworld;

