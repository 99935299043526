import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Diamondrobber() {
  document.title = 'Diamond Robber | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play Online - Diamond Robber</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    href="games/diamondrobber/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_diamondrobber.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/diamondrobber.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 "> Diamond Robber</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Jump and dash your way over, under and through ruined towers with the easy to learn two button
                    controls. Dash enemies and get combos, while avoiding arrows, spears and axes!<br /><br />Enjoy the
                    eternally valid story of overcoming our everyday obstacles.<br /><br />&quot;In Dashy Knight there’s
                    only two things to do: jump and dash. Those simple controls offer hours of addictive fun. The goal is to
                    stay alive as long as possible and watch your score rise. The controls are simple, the design is
                    awesome, and it’s completely free without ads.&quot; -Phandroid<br /><br />• Jump, double jump and dash
                    to avoid obstacles and kill enemies<br /><br />• Unlock skins to change the appearance of the
                    knight<br /><br />• Unlock new worlds<br /><br />• Collect achievements<br /><br />• Get your score to
                    the top of the leaderboard of each world<br /><br />• Easy to control: Dash by tapping the right side of
                    the screen, jump by tapping the left side<br /><br />• Beautiful retro-inspired graphics and sound
                    effects<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Action Games</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Play Diamond Robber Game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Flash Online Game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Diamond Robber Game Online</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Diamond Robber Game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Play Diamond Robber Game Online</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Diamondrobber;

