import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Advantureousboyrun() {
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    document.title = 'Advantureous Boy Run | omeGames';
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Advantureous Boy Run</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/advantureousboyrun/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_advantureousboyrun.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/advantureousboyrun.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Advantureous Boy Run</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Adventurous Boy Run is an exciting and fast-paced mobile game where players take on the role of a young
                    boy on a thrilling adventure through treacherous landscapes. With stunning graphics and an immersive
                    storyline, players will be drawn into a world of danger and excitement as they navigate through a
                    variety of challenging levels.<br /><br />The game begins with the boy setting out on a quest to find a
                    powerful artifact that has been hidden in a dangerous and mysterious land. Along the way, he must jump,
                    slide, and dodge his way past obstacles, enemies, and other hazards to reach his goal. With each level,
                    the challenges become more difficult, and players must use their quick reflexes and strategic thinking
                    to survive.<br /><br />Adventurous Boy Run features multiple modes of gameplay, including endless runner
                    and time trial modes, where players compete for high scores and leaderboard rankings. Players can also
                    collect coins and power-ups to enhance their abilities and help them on their journey.<br /><br />The
                    game is designed with intuitive touch controls, allowing players to easily maneuver their character
                    through the levels. The vibrant and colorful graphics, along with the exciting soundtrack, create an
                    immersive and engaging gameplay experience that will keep players coming back for more.<br /><br />With
                    its addictive gameplay, thrilling storyline, and stunning visuals, Adventurous Boy Run is sure to be a
                    hit with gamers of all ages. Whether you&#x27;re a seasoned gamer or new to the world of mobile gaming,
                    this exciting adventure is one you won&#x27;t want to miss.<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH"> Endless runner game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Adventure game for kids </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Challenging obstacle course </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Fun platform game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Cartoon character game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Running and jumping game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Exciting action game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Thrilling escape game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Addictive arcade game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Skill-based running game </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Advantureousboyrun;
