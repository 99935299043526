import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Motoracer() {
  document.title = 'Moto Racer | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Moto Racer</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/motoracer/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_motoracer.png" alt="Game"
                    draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/motoracer.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Moto Racer</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Moto Racer is an exciting racing game that puts players in control of a powerful motorbike as they race
                    through various tracks and terrains. With its fast-paced gameplay, realistic physics, and stunning
                    graphics, Moto Racer is a game that&#x27;s sure to get your heart racing.<br /><br />In Moto Racer,
                    players can choose from a variety of different motorbikes, each with its own unique features and
                    strengths. They can then race through various tracks, including urban streets, countryside roads, and
                    off-road terrain, each with their own challenges and obstacles.<br /><br />The game features intuitive
                    touch controls, allowing players to easily accelerate, brake, and turn their motorbike as they race
                    through the world. Players must also strategically use nitro boosts and other power-ups to gain an
                    advantage over their opponents and finish the race in first place.<br /><br />Moto Racer is designed
                    with stunning graphics and realistic physics, creating a thrilling and immersive racing experience. With
                    its multiple game modes, including career mode, time trial mode, and multiplayer mode, there&#x27;s
                    always a new challenge to take on and a new race to win.<br /><br />Whether you&#x27;re a fan of racing
                    games or just looking for an exciting and immersive mobile game to play, Moto Racer is a game
                    that&#x27;s sure to provide hours of entertainment. With its fast-paced gameplay, challenging tracks,
                    and stunning graphics, it&#x27;s a game that&#x27;s not to be missed.<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH"> Fast-paced racing game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Exciting moto game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Challenging motorcycle game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Addictive racing game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> High-speed bike game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Thrilling racing adventure game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Kid-friendly motorcycle game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Family-friendly racing game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Intense moto racing game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Multi-level moto game. </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Motoracer;

