import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Zombiehunter() {
  document.title = 'Zombie Hunter | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Zombie Hunter</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                  rel="noreferrer" href="games/zombiehunter/">
                  <div class="PlayBtn" id="playBtn">Play Game</div>
                </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_zombiehunter.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/zombiehunter.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Zombie Hunter</span><span
                      class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                        alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Zombie Hunter is an action-packed mobile game that puts players in the shoes of a skilled hunter tasked
                    with taking down hordes of undead zombies. With its intense gameplay, challenging levels, and immersive
                    graphics, Zombie Hunter is a game that&#x27;s sure to keep players on the edge of their
                    seats.<br /><br />In Zombie Hunter, players must navigate through a variety of different levels, each
                    filled with hordes of flesh-eating zombies. Armed with an array of powerful weapons and special
                    abilities, players must strategically take down the zombies and survive for as long as
                    possible.<br /><br />The game features intuitive touch controls, allowing players to easily move their
                    character around the world and aim and shoot their weapons with precision. As players progress through
                    the levels, they must collect power-ups and other items to help them survive and take down the
                    zombies.<br /><br />Zombie Hunter is designed with immersive graphics and a dark, eerie soundtrack that
                    adds to the overall experience, creating a creepy and intense atmosphere. With its challenging gameplay,
                    fast-paced action, and endless replayability, it&#x27;s a game that&#x27;s hard to put
                    down.<br /><br />Whether you&#x27;re a fan of zombie-themed games or just looking for an intense action
                    game to play, Zombie Hunter is a game that&#x27;s sure to provide hours of entertainment. With its
                    addictive gameplay, challenging levels, and immersive graphics, it&#x27;s a game that&#x27;s not to be
                    missed.<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH"> Action-packed zombie game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Intense shooting game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Addictive zombie shooter game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Post-apocalyptic game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Thrilling zombie hunting game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Challenging survival game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Scary zombie game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Futuristic zombie shooter game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Kid-friendly zombie game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Multi-level zombie game. </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Zombiehunter;

