import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Cutedressup() {
  document.title = 'Cute Dress Up | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Cute Dress Up</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/cutedressup/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_cutedressup.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/cutedressup.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Cute Dress Up</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Cute Girl Dressup Kids is a fun and engaging mobile game that lets kids unleash their creativity and
                    imagination by dressing up an adorable character in a variety of stylish outfits. With its colorful
                    graphics and easy-to-use controls, this game is perfect for young children who love fashion and
                    design.<br /><br />The game features a cute girl character who is ready to be dressed up in a variety of
                    outfits, including dresses, tops, pants, and accessories. Players can choose from a variety of colors
                    and styles, allowing them to create their own unique and fashionable looks.<br /><br />In addition to
                    dressing up the character, players can also choose from a variety of hairstyles and makeup options to
                    complete the look. The game also features a variety of backgrounds and settings, allowing players to
                    create their own unique fashion photoshoots.<br /><br />Cute Girl Dressup Kids is designed with
                    intuitive touch controls, allowing even young children to easily navigate the game and create their own
                    fashion masterpieces. The colorful graphics and upbeat music add to the overall experience, creating a
                    fun and engaging gameplay experience.<br /><br />Whether you&#x27;re a parent looking for a fun and safe
                    game for your child or a young fashionista looking for a creative outlet, Cute Girl Dressup Kids is a
                    game that is sure to please. With its endless possibilities for fashion and design, it&#x27;s a game
                    that will keep kids entertained and engaged for hours.<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH"> Fashion and dress-up game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Cute character game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Fun makeover game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Trendy dress-up game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Virtual styling game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Girl&#x27;s fashion game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Kid-friendly dress-up game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Creative dress-up game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Fantasy dress-up game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Personal styling game for kids. </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Cutedressup;

