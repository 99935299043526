import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Chickenshooter() {
  document.title = 'Chicken Shooter | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Chicken Shooter</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/chickenshooter/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_chickenshooter.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/chickenshooter.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Chicken Shooter</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Chicken Shooter is a fun and addictive mobile game where players take on the role of a farmer defending
                    their farm from a flock of pesky chickens. With colorful graphics and fast-paced gameplay, this game is
                    sure to keep players engaged and entertained for hours.<br /><br />The game features multiple levels,
                    each with increasing difficulty and a variety of challenges. Players must use their aim and quick
                    reflexes to shoot down the chickens before they reach the farmer&#x27;s crops. The chickens move quickly
                    and unpredictably, so players must be strategic and precise with their shots.<br /><br />As players
                    progress through the levels, they can unlock new weapons and power-ups to help them on their mission.
                    These include things like grenade launchers, flamethrowers, and more. Players can also earn coins and
                    other rewards by completing levels and achieving high scores.<br /><br />Chicken Shooter is designed
                    with intuitive touch controls, allowing players to easily aim and shoot their weapons. The colorful
                    graphics and fun sound effects add to the overall experience, creating an immersive and entertaining
                    gameplay experience.<br /><br />Whether you&#x27;re a casual gamer looking for a fun distraction or a
                    dedicated player seeking a challenge, Chicken Shooter is a game that is sure to please. With its
                    addictive gameplay, challenging levels, and quirky premise, it&#x27;s a game that will keep you coming
                    back for more.<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH"> Arcade-style shooting game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Farm animal shooter </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Addictive shooting game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Target practice game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Challenging chicken hunt </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Fun bird shooting game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Fast-paced shooting game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Exciting animal attack game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Multi-level shooter game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Kid-friendly shooting game </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Chickenshooter;
