import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Duckhunt() {
  document.title = 'Duck Hunt | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Duck Hunt</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/duckhunt/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_duckhunt.png" alt="Game"
                    draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/duckhunt.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Duck Hunt</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Duck Hunt is a classic arcade-style shooting game that has been entertaining players for decades.
                    Originally released in 1984 by Nintendo, Duck Hunt has since become a beloved classic that has been
                    adapted to various platforms, including mobile devices.<br /><br />In Duck Hunt, players control a
                    hunting dog as they attempt to shoot down flying ducks. The game features multiple levels, each with
                    their own challenges and objectives. Players must aim and shoot with precision to hit the ducks and earn
                    points.<br /><br />As players progress through the levels, the game becomes more challenging, with
                    faster and more elusive ducks to shoot. Players must also avoid shooting the friendly hunting dog, which
                    can result in lost points and a lower score.<br /><br />Duck Hunt is designed with simple yet addictive
                    gameplay, making it easy to pick up and play. The game also features colorful graphics and a fun
                    soundtrack that add to the overall experience.<br /><br />Whether you&#x27;re a fan of classic
                    arcade-style games or just looking for a fun and nostalgic game to play, Duck Hunt is a game that&#x27;s
                    sure to provide hours of entertainment. With its simple yet challenging gameplay, colorful graphics, and
                    classic arcade style, it&#x27;s a game that has stood the test of time and continues to be enjoyed by
                    players of all ages.<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH"> Classic shooting game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Fun bird hunting game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Challenging target shooting game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Arcade-style shooting game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Skill-based hunting game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Multi-level hunting game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Addictive shooting game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Virtual hunting game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Family-friendly shooting game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Kid-friendly hunting game. </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Duckhunt;

