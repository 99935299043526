import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Stickman() {
  document.title = 'Stickman | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Stickman</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/stickman/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_stickman.png" alt="Game"
                    draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/stickman.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 "> Stickman</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Stick Man is a fun and addictive mobile game that puts players in control of a stick figure character,
                    challenging them to navigate through a variety of levels and obstacles.<br /><br />In Stick Man, players
                    must guide their character through levels filled with dangerous obstacles, including spikes, pits, and
                    enemies. The game features intuitive touch controls, allowing players to easily jump, run, and fight
                    their way through each level.<br /><br />The game features a variety of power-ups and upgrades, which
                    players can use to enhance their stick figure character&#x27;s abilities and take on even greater
                    challenges. With each level, the game becomes more challenging, testing players&#x27; reflexes and
                    strategy.<br /><br />Stick Man is designed with simple graphics, creating a charming and quirky gameplay
                    experience. With its easy-to-learn mechanics and addictive gameplay, players can enjoy the game for
                    minutes or hours at a time.<br /><br />Whether you&#x27;re a fan of platformer games or just looking for
                    a fun and addictive mobile game to play, Stick Man is a game that&#x27;s sure to provide endless
                    entertainment. With its intuitive controls, fun gameplay, and challenging obstacles, it&#x27;s a game
                    that&#x27;s hard to put down.<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH"> Addictive stickman game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Exciting stickman adventure game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Challenging stickman puzzle game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Action-packed stickman game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Thrilling stickman fighting game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Kid-friendly stickman game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Family-friendly stickman game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Multi-level stickman game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Easy-to-play stickman game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Fun and entertaining stickman game for all ages. </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Stickman;

