import Header from './includes/Header';

function PrivacyPolicy() {
  return (
    <>
      <div id="__next">
        <Header />
        <main>
          <div class="container mt-3">
            <div class="">
              <script type='text/javascript'> 
                google_ad_client = "pub-3990886853793852";
                google_ad_host = "pub-2885846345242349"; 
                google_ad_width = 728; 
                google_ad_height = 90; 
                google_ad_type = "text_image"; 
                google_color_border = "FFFFFF"; 
                google_color_bg = "ffffff"; 
                google_color_link = "0000ff"; 
                google_color_text = "000000"; 
                google_color_url = "008000"; 
                google_page_url = "https://heylink.me/"; 
              </script>
              <script src='https://pagead2.googlesyndication.com/pagead/show_ads.js' type='text/javascript'></script>
              <h1>Privacy Policy and Terms of Use for www.omegames.in</h1>
              <h2>1. Introduction</h2>
              <p class="text-secondary">Welcome to www.omegames.in! This Privacy Policy and Terms of Use govern your use of
                our website, www.omegames.in, and any related services offered by omeGames.</p>
              <p class="text-secondary">By using our website, you agree to the terms outlined in this policy. Please read
                this document carefully to understand how we collect, use, and protect your information.</p>
              <h3 class="mb-3">2. Data Collection</h3>
              <p class="text-secondary">We don&#x27;t collect any user inforamtion or data from users</p>
              <h3 class="mb-3">3. Use of Data</h3>
              <p class="text-secondary">We collect and use your data for the following purposes:</p>
              <p class="text-secondary ms-5">• To provide and maintain our website.</p>
              <p class="text-secondary ms-5">• To manage your account and affiliate partner program participation.</p>
              <p class="text-secondary ms-5">• To communicate with you regarding your account, inquiries, and updates.</p>
              <p class="text-secondary ms-5">• To enhance our website&#x27;s functionality and user experience.</p>
              <p class="text-secondary ms-5">• To comply with legal obligations.</p>
              <h2>4. Third-Party Services</h2>
              <p class="text-secondary">While we do not allow third parties to access or process data directly, we may use
                third-party payment processors to facilitate transactions and payments on our website. Please review their
                respective privacy policies for more information.</p>
              <h2>5. Location of Data Processing</h2>
              <p class="text-secondary">Your data will be processed in the United States, where our servers are located.
              </p>
              <h2>6. Children&#x27;s Privacy</h2>
              <p class="text-secondary">Our website is accessible to children; however, it is not designed or intended for
                children under the age of 13. We do not knowingly collect personal information from children under 13. If
                you believe that a child has provided us with their personal information, please contact us, and we will
                promptly delete such information.</p>
              <h2>7. Security Measures</h2>
              <p class="text-secondary">We implement reasonable security measures to protect your personal information
                from unauthorized access, disclosure, alteration, or destruction. However, no data transmission over the
                internet or storage system can be guaranteed as 100% secure. Please take appropriate precautions when
                sharing your information online.</p>
              <p><strong>We do not collect or store any information related to gambling activities. This platform does not
                  include any gambling content.</strong></p>
              <p><strong>In our games all the conis is vertual but there is not real value of it.</strong></p>
              <p><strong>All the games for a fun and entatement perpose we do not ask to do any peyment collection from
                  user side.</strong></p>
              <p class="text-secondary">Last updated: 06/12/2022</p>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default PrivacyPolicy;
