import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Bottlejump() {
  document.title = 'Bottle Jump | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Bottle Jump</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/bottlejump/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_bottlejump.png" alt="Game"
                    draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/bottlejump.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Bottle Jump</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>With &quot;Bottle Jump,&quot; the ultimate bottle challenge that will put your talents to the test, you
                    can feel the rush of accuracy and timing. You&#x27;ll set out on a quest to perfect the skill of
                    expertly flipping bottles in this engrossing game.<br /><br />You must make your way through a number of
                    levels, each of which introduces fresh challenges and intricacies as you try to flip the bottle
                    correctly and land it upright. &quot;Bottle Jump&quot; is an interesting option for players of all ages
                    since it provides an intriguing blend of strategy and quick reflexes.<br /><br />Whether you&#x27;re a
                    casual gamer looking for a fun challenge or a serious player trying to polish your bottle-flipping
                    skills, this game will keep you entertained for hours on end. In &quot;Bottle Jump,&quot; test your
                    ability to master the perfect flip and become a master bottle-flipper.<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH"> Bottle Jump game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Flip bottle challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Precision bottle flipping</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Bottle flip gameplay</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Skillful bottle jump</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Quick reflex bottle challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Bottle flipping adventure</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Flip and land game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Addictive bottle jump</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Timing and strategy game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Bottle flipping mastery</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Exciting jump and flip</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Precision flipping simulator</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Bottle challenge levels</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Bottle flip frenzy</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Bottlejump;
