import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Yummycandies() {
  document.title = 'Yummy Candies | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Yummy Candies</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/yummycandies/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_yummycandies.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/yummycandies.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Yummy Candies</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Yummy Candies is a fun and addictive mobile game where players match colorful candies to progress
                    through levels and achieve high scores. With its bright and colorful graphics, easy-to-learn gameplay,
                    and a wide variety of levels, Yummy Candies is the perfect game for anyone who loves candy and
                    puzzles.<br /><br />The game features a variety of different candies, each with its own unique shape and
                    color. Players must swap adjacent candies to create lines of three or more of the same candies in a row,
                    column or diagonally to make them disappear from the board. The more candies they match in a single
                    move, the higher the score they earn.<br /><br />As players progress through the levels, the game
                    becomes more challenging with different obstacles and objectives to overcome. Players must strategize
                    and use their skills to create power-ups and special candies to help them progress through the levels
                    and earn high scores.<br /><br />Yummy Candies is designed with intuitive touch controls, allowing
                    players to easily swap and match candies with just a few taps. The game also features colorful graphics
                    and a fun soundtrack that add to the overall experience, creating a fun and engaging gameplay
                    experience.<br /><br />Whether you&#x27;re looking for a quick distraction or a challenging puzzle game,
                    Yummy Candies is a game that will keep you entertained for hours. With its addictive gameplay,
                    challenging levels, and colorful candy visuals, it&#x27;s a game that&#x27;s hard to put
                    down.<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH"> Candy-matching game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Addictive puzzle game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Colorful candy game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Sweet treats game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Candy crush game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Candy blast game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Fun candy-themed game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Challenging candy puzzle game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Delicious candy game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Kid-friendly candy game. </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Yummycandies;

