import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Touchthealphabetintheorder() {
  document.title = 'Touch Alphabets in order | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play Online - Touch Alphabets in order</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    href="games/touchthealphabetintheorder/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img
                    src="assets/Game/banners/banner_touchthealphabetintheorder.png" alt="Game" draggable="false"
                    class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/touchthealphabetintheorder.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Touch Alphabets in
                        order</span><span class="cl-peta d-flex justify-content-start align-items-center"><img
                          src="assets/Game/watch.png" alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Super Alphabets for kids is where your child can play letter games for kids and learning games for
                    toddlers to learn letters. Let’s get this alphabet game started!<br /><br />Alphabet games like those
                    with super baby letters help your child get familiar with the alphabetical order, as well as the letters
                    and sounds themselves. Learning games for kindergarten prep and learning games for kids 3 years free
                    young eager minds from the dullness of repetition by giving them amusing lessons with our super baby
                    letters. Not only that but expert-designed educational games for kids and preschool games free up your
                    time as well by keeping your kid busy.<br /><br />Our super letters will guide you through loads of
                    super abc games for kids freely and joyfully, teaching letters and sounds with letters learning games
                    for kids. Our amazing alphabet app features:<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Learn Alphabets</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Alphabets sequence</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Alphabets Game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Touch in sequence</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Play Alphabets</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Catch Alphabets</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Follow Alphabets</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">ABCD game</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Touchthealphabetintheorder;

