import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Saveyourpuppies() {
  document.title = 'Save Your Puppies | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Save Your Puppies</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/saveyourpuppies/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_saveyourpuppies.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/saveyourpuppies.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Save Your Puppies</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Embark on a heartwarming mission with &quot;Save the Puppies,&quot; a touching and exciting game that
                    will melt your heart. In this enchanting adventure, your task is to rescue adorable puppies scattered
                    across challenging levels. Navigate through vibrant landscapes, solve puzzles, and overcome obstacles to
                    reunite these furry friends with their families.<br /><br />&quot;Save the Puppies&quot; offers a
                    delightful mix of puzzle-solving and strategy, appealing to gamers of all ages. With its captivating
                    visuals and intuitive gameplay, you&#x27;ll be immersed in a world where every decision counts. Explore
                    a variety of environments, each brimming with unique challenges and surprises.<br /><br />As you
                    progress, you&#x27;ll feel the joy of rescuing these lovable pups and witnessing heartwarming reunions.
                    Get ready to experience an emotional and rewarding journey that combines entertainment with a sense of
                    purpose. Play &quot;Save the Puppies&quot; today and discover the joy of being a hero for these
                    four-legged companions!<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Save your Puppies game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Puppy rescue challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Heartwarming pet game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Furry friends adventure</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Puppy-saving gameplay</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Animal rescue simulation</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Cute puppy game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Heroic pet rescue game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Virtual puppy care</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Heartfelt animal game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Puppy protection challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Adorable pet adventure</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Pet-saving simulation</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Challenging puppy rescue</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Compassionate animal game</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Saveyourpuppies;

