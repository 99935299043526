import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Spacebattle() {
  document.title = 'Space Battle | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Space Battle</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/spacebattle/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_spacebattle.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/spacebattle.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Space Battle</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Space Battle is an epic game of intergalactic warfare where players command their own starships and
                    battle for control of the galaxy. With stunning graphics and a thrilling storyline, players will be
                    transported to a world of futuristic combat where they must outwit their enemies and emerge
                    victorious.<br /><br />At the beginning of the game, players will choose their faction and select their
                    starting ship. They can then customize their ship with weapons, armor, and other upgrades to give
                    themselves an edge in battle. As they progress through the game, they can earn credits and reputation
                    points by completing missions, defeating enemy ships, and exploring new sectors of the
                    galaxy.<br /><br />Players can engage in both single-player and multiplayer modes, competing against
                    other players in epic space battles that will test their strategy and skill. They can team up with other
                    players to form alliances and work together to dominate the galaxy, or go it alone and take on all
                    challengers.<br /><br />With a vast array of ships, weapons, and upgrades to choose from, Space Battle
                    offers endless hours of gameplay and excitement. Whether you&#x27;re a seasoned gamer or new to the
                    world of space combat, this game is sure to provide an unforgettable experience that will keep you
                    coming back for more.<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH"> Space shooter game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Intergalactic battles </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Sci-fi themed game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Alien invasion game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Space combat game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Space adventure game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Futuristic shooter game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Epic space battles </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Space exploration game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Action-packed space game </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Spacebattle;

