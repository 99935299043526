import { useState } from 'react';
import Header from './includes/Header';
import axios from 'axios';
import { AFFILIATE_PROGRAM } from '../Apis';

function Affiliate() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });
    const setData = (e) => {
        setFormData({...formData, [e.target.name] : e.target.value});
    }
    const submitForm = async () => {
        if(formData.name == ''){
            alert('enter name');
        } else if(formData.email == ''){
            alert('enter email');
        } else if(formData.phone == ''){
            alert('enter mobile');
        } else if(formData.message == ''){
            alert('enter message');
        } else {
            let response = await axios({
                method: 'post',
                url: AFFILIATE_PROGRAM,
                data: formData,
                mode: 'cors',
                headers: {
                    "Content-Type": 'application/x-www-form-urlencoded',
                }
            });
            document.getElementById("affiliatesPartner").reset();
            setFormData({
                name: '',
                email: '',
                phone: '',
                message: '',
            });
            alert(response.data.message);
        }
    }
  return (
    <>
      <div id="__next">
        <Header />
        <section className="my-3">
            <div className="container">
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <form id="affiliatesPartner">
                        <section className="my-5 AboutTxt">
                            <div className="container">
                                <div className="row d-flex justify-content-center align-items-center ovrHdn">
                                    <div className="col-lg-6 mt-5 mt-lg-0">
                                        <div className="f-36 fw-bold cl-theme lh_44 text-center HeadBG">Affiliates Partner</div>
                                        <div className="mt-3">
                                            <input type="text" className="ContactUsInput bgUser" name="name" placeholder="Your Name" onChange={setData} />
                                            <input type="email" className="ContactUsInput bgEmail my-3" name="email" placeholder="Email Address" onChange={setData} />
                                            <input type="number" className="ContactUsInput bgCall" name="phone" placeholder="Phone Number" onChange={setData} />
                                            <textarea name="message" className="ContactUsInput my-3" rows="5" placeholder="Enter Message" onChange={setData} ></textarea>
                                            <button type="button" className="cusBtn text-uppercase text-center mt-2 w-100" onClick={submitForm} >Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </form>
                </div>
            </div>
        </section>
        <section className="my-5 AboutTxt">
            <div className="container">
            <h1 className="HeadBG">Affiliate Program- omeGames</h1><br />
            <p>We welcomes web as well as mobile apps developers to drive traffic to our website and start earning money
                through your traffic.</p><br />
            <h1 className="HeadBG">We Welcomes</h1><br />
            <p>We have an extensive range of HTML5 games, optimized for both desktop and mobile devices. You can easily
                choose a few games or set up your own gaming portal as you wish. We are working hard to make sure all your
                demands are met – especially in terms of content and marketing. We strive to make sure our partners get the
                best possible results</p><br /><br />
            <h1 className="HeadBG">Contact Us and Join Now</h1><br /><br />contact@www.omegames.in<br /><br />
            <h1 className="HeadBG">About omeGames</h1><br /><br />
            <p>omeGames has a team of 25 people working on our gaming platform. Our mission is simple - to create a
                browser-gaming platform that works seamlessly for users around the world, and rewards developers both big
                and small.<br /><br />Our games are playable on desktop, tablet and mobile so you can enjoy them at school,
                at home or on the road. Every month millions of gamers from all over the world play their favorite games on
                omeGames. Our goal is to create the ultimate online playground. Free and open to all.<br /><br />We&#x27;re
                a team of makers, techies, adventurers – and some gamers too. We’re kids of all ages, and love what we
                do.<br /><br />Just load up your favorite games instantly in your web browser and enjoy the
                experience.<br /><br />You can play our games on desktop mobile devices. That includes everything from
                desktop PCs, laptops, and Chromebooks, to the latest smartphones and tablets from Apple and Android.</p>
            <br />
            </div>
        </section>
      </div>
    </>
  );
}

export default Affiliate;
