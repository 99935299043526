import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Carbreaker() {
  document.title = 'Car Breaker | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Car Breaker</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/carbreaker/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_carbreaker.png" alt="Game"
                    draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/carbreaker.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Car Breaker</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>The newest craze in the world of racing games in 2023, Car Breaker will give you the ultimate rush of
                    speed and accuracy. As you take on the task of handling strong cars and conquering the racetrack, get
                    ready for a thrilling experience.<br /><br />Both casual gamers and die-hard racing lovers can enjoy the
                    unrivaled racing experience that Car Breaker offers. This game puts you in the driver&#x27;s seat and
                    allows you to experience every bend and curve as if you were actually there thanks to its breathtaking
                    graphics, realistic physics, and well planned tracks. Every race in Car Breaker is a test of your
                    ability and planning.<br /><br />Push yourself to the edge as you take on challenging driving tasks on a
                    variety of tracks that call for accuracy and speed.<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Car Breaker game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Racing game 2023</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Car challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Virtual racetrack</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Intense car racing</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Thrilling car gameplay</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Precision racing experience</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">High-speed racing</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Customizable cars</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Adrenaline-pumping races</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Realistic physics racing</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Top-tier racing simulator</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Mastering powerful cars</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Heart-pounding action</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Hardcore racing enthusiasts</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Carbreaker;
