import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Starfallescape() {
  document.title = 'Star Fall Escape | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Star Fall Escape</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/starfallescape/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_starfallescape.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/starfallescape.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Star Fall Escape</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Star Falls Escape, the top website for star-falling games, invites you to set out on a cosmic voyage.
                    Get lost in a fascinating realm where stars fall in a breathtaking show. You can travel across stunning
                    constellations and cosmic vistas while playing this captivating star game.<br /><br />As you navigate
                    falling stars through complex mazes and gather cosmic treasures along the way, you&#x27;ll have to test
                    your reflexes and strategy. Star Falls Escape promises hours of heavenly fun with its gorgeous graphics
                    and engaging gameplay. This game offers an otherworldly trip that captivates and pleases players of all
                    skill levels, from casual gamers to starry-eyed enthusiasts. Star Falls Escape invites you to indulge in
                    the allure of the cosmos and set out on an exciting voyage.<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Star Falls Escape game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Falling star game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Celestial adventure</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Starry landscapes</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Cosmic treasures</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Mesmerizing constellations</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Captivating star gameplay</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Celestial journey</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Enchanting visuals</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Cosmic mazes</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Reflex challenge game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Astral entertainment</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Starry-eyed gaming</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Immersive star experience</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Dazzling cascade game</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Starfallescape;

