import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Heroboxjumping() {
  document.title = 'Hero Box Jumping | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Hero Box Jumping</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/heroboxjumping/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_heroboxjumping.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/heroboxjumping.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Hero Box Jumping</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Dive into the world of Hero Box Jumping, a captivating hero game that combines strategic planning with
                    thrilling challenges.<br /><br /> Become the fearless box hero as you navigate through a series of
                    obstacles, using your wits and skills to conquer each level. <br /><br />This unique box game challenge
                    will put your reflexes and strategic thinking to the test as you jump from platform to platform, aiming
                    for victory. Hero Box Jumping offers an immersive experience that engages players of all
                    levels.<br /><br /> Plan your moves carefully to ensure successful leaps, avoid pitfalls, and reach new
                    heights. The dynamic gameplay and vibrant visuals create an exhilarating adventure that will keep you
                    hooked.<br /><br /> Unleash your inner box hero and embark on a journey filled with excitement,
                    challenges, and triumphs. Play Hero Box Jumping now and showcase your box game planning skills!</p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Hero Box Jumping game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Jumping challenge gameplay</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Box hero adventure</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Strategic box jumping</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Precision jump game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Heroic box hopping</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Dynamic jumping challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Box hopping strategy</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Addictive hero game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Agility and precision game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Jumping levels adventure</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Reflex-based box jumping</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Box hopping puzzle</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Climbing heights challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Action-packed jumping game</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Heroboxjumping;

