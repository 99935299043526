import { Route, Routes } from 'react-router-dom';
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import Home from './pages/Home';
import Affiliate from './pages/Affiliate';
import Developer from './pages/Developer';
import ContactUs from './pages/ContactUs';
import AboutUs from './pages/AboutUs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Advantureousboyrun from './pages/games/Advantureousboyrun';
import Airbattlemission from './pages/games/Airbattlemission';
import Alienonearth from './pages/games/Alienonearth';
import Angrybubble from './pages/games/Angrybubble';
import Arrowpatching from './pages/games/Arrowpatching';
import Ballbounceescape from './pages/games/Ballbounceescape';
import Ballbouncerwall from './pages/games/Ballbouncerwall';
import Ballout from './pages/games/Ballout';
import Game from './pages/games/2048';
import Balloonpop from './pages/games/Balloonpop';
import Bemillionaire from './pages/games/Bemillionaire';
import Bottleclimbing from './pages/games/Bottleclimbing';
import Bottlegarbage from './pages/games/Bottlegarbage';
import Bottlejump from './pages/games/Bottlejump';
import Brainexcercisegame from './pages/games/Brainexcercisegame';
import Burningballs from './pages/games/Burningballs';
import Carbreaker from './pages/games/Carbreaker';
import Chickenshooter from './pages/games/Chickenshooter';
import Choosetruecolors from './pages/games/Choosetruecolors';
import Christmasgift from './pages/games/Christmasgift';
import Christmasholiday from './pages/games/Christmasholiday';
import Christmassantarun from './pages/games/Christmassantarun';
import Coinsnake from './pages/games/Coinsnake';
import Collectthesameballs from './pages/games/Collectthesameballs';
import Colorjumpgame from './pages/games/Colorjumpgame';
import Colormatch from './pages/games/Colormatch';
import Cookiesboockies from './pages/games/Cookiesboockies';
import Cricketbatter from './pages/games/Cricketbatter';
import Crossyroad from './pages/games/Crossyroad';
import Cutedressup from './pages/games/Cutedressup';
import Darkforest from './pages/games/Darkforest';
import Darkknightrider from './pages/games/Darkknightrider';
import Dashcolorswitch from './pages/games/Dashcolorswitch';
import Dashingdonut from './pages/games/Dashingdonut';
import Diamondrobber from './pages/games/Diamondrobber';
import Dragonfish from './pages/games/Dragonfish';
import Drivesafe from './pages/games/Drivesafe';
import Droptheappleintomouth from './pages/games/Droptheappleintomouth';
import Duckhunt from './pages/games/Duckhunt';
import Eggbucketmaster from './pages/games/Eggbucketmaster';
import Eggcatcher from './pages/games/Eggcatcher';
import Endlessninjarun from './pages/games/Endlessninjarun';
import Fighterjet from './pages/games/Fighterjet';
import Fillthedot from './pages/games/Fillthedot';
import Firealarmresquers from './pages/games/Firealarmresquers';
import Fishcatcher from './pages/games/Fishcatcher';
import Flowershooter from './pages/games/Flowershooter';
import Flyingtriangle from './pages/games/Flyingtriangle';
import Goalpostchallenge from './pages/games/Goalpostchallenge';
import Golfpost from './pages/games/Golfpost';
import Gopanda from './pages/games/Gopanda';
import Halloweenhooks from './pages/games/Halloweenhooks';
import Halloweenrun from './pages/games/Halloweenrun';
import Happymood from './pages/games/Happymood';
import Harvestveggie from './pages/games/Harvestveggie';
import Heroboxjumping from './pages/games/Heroboxjumping';
import Highwayhero from './pages/games/Highwayhero';
import Icecandy from './pages/games/Icecandy';
import Icymatch from './pages/games/Icymatch';
import Jellybellyjam from './pages/games/Jellybellyjam';
import Jellyworld from './pages/games/Jellyworld';
import Jewelinthrowchallenge from './pages/games/Jewelinthrowchallenge';
import Joytoytrain from './pages/games/Joytoytrain';
import Jumpandcollectcoins from './pages/games/Jumpandcollectcoins';
import Lemonjuicer from './pages/games/Lemonjuicer';
import Ludomultiplayer from './pages/games/Ludomultiplayer';
import Makeabridgeandgogetdiamond from './pages/games/Makeabridgeandgogetdiamond';
import Mannequinheadjump from './pages/games/Mannequinheadjump';
import Motoracer from './pages/games/Motoracer';
import Movingsparrow from './pages/games/Movingsparrow';
import Mrpingjumpingjoy from './pages/games/Mrpingjumpingjoy';
import Musicianmagic from './pages/games/Musicianmagic';
import Nanoraceescape from './pages/games/Nanoraceescape';
import Noobvspro from './pages/games/Noobvspro';
import Perfectpoolshot from './pages/games/Perfectpoolshot';
import Perfectrotare from './pages/games/Perfectrotare';
import Pixelfiller from './pages/games/Pixelfiller';
import Pixelninza from './pages/games/Pixelninza';
import Pocketjumparcade from './pages/games/Pocketjumparcade';
import Pongsdot from './pages/games/Pongsdot';
import Potfiller from './pages/games/Potfiller';
import Protectredindianman from './pages/games/Protectredindianman';
import Reversespaceriding from './pages/games/Reversespaceriding';
import Roadrace2d from './pages/games/Roadrace2d';
import Rocketpilot from './pages/games/Rocketpilot';
import Rollingcubeadvantures from './pages/games/Rollingcubeadvantures';
import Romeoswiftracer from './pages/games/Romeoswiftracer';
import Runningninja from './pages/games/Runningninja';
import Saveyourpuppies from './pages/games/Saveyourpuppies';
import Sawsescapes from './pages/games/Sawsescapes';
import Shoottogiantbats from './pages/games/Shoottogiantbats';
import Shootzombie from './pages/games/Shootzombie';
import Spacebattle from './pages/games/Spacebattle';
import Speedsquarerunner from './pages/games/Speedsquarerunner';
import Squareescape from './pages/games/Squareescape';
import Starfallescape from './pages/games/Starfallescape';
import Stickman from './pages/games/Stickman';
import Stressreleasingdots from './pages/games/Stressreleasingdots';
import Supercandy from './pages/games/Supercandy';
import Tapcounterchallenge from './pages/games/Tapcounterchallenge';
import Tennisballarcade from './pages/games/Tennisballarcade';
import Touchthealphabetintheorder from './pages/games/Touchthealphabetintheorder';
import Towerbalancer from './pages/games/Towerbalancer';
import Universaladvanture from './pages/games/Universaladvanture';
import Waroftank from './pages/games/Waroftank';
import Yummycandies from './pages/games/Yummycandies';
import Zombieattack from './pages/games/Zombieattack';
import Zombiehunter from './pages/games/Zombiehunter';
import Kingdomfight2 from './pages/games/Kingdomfight2';
// const firebaseConfig = {
//     apiKey: "AIzaSyBGC4Iu5yO63QX3pqxqYai5dLXERrG_PXA",
//     authDomain: "majagaja-dl.firebaseapp.com",
//     projectId: "majagaja-dl",
//     storageBucket: "majagaja-dl.appspot.com",
//     messagingSenderId: "1091843839572",
//     appId: "1:1091843839572:web:b7b8434c9e89153f2cbfc1",
//     measurementId: "G-K08YKP6MBM"
// };
// const apps = initializeApp(firebaseConfig);
// const analytics = getAnalytics(apps);
function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/affiliate" element={<Affiliate />} />
      <Route exact path="/developer" element={<Developer />} />
      <Route exact path="/contactus" element={<ContactUs />} />
      <Route exact path="/aboutus" element={<AboutUs />} />
      <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
      
      {/* games Routes */}

      <Route exact path="/2048" element={<Game />} />
      <Route exact path="/advantureousboyrun" element={<Advantureousboyrun />} />
      <Route exact path="/airbattlemission" element={<Airbattlemission />} />
      <Route exact path="/alienonearth" element={<Alienonearth />} />
      <Route exact path="/angrybubble" element={<Angrybubble />} />
      <Route exact path="/arrowpatching" element={<Arrowpatching />} />
      <Route exact path="/ballbounceescape" element={<Ballbounceescape />} />
      <Route exact path="/ballbouncerwall" element={<Ballbouncerwall />} />
      <Route exact path="/Ballout" element={<Ballout />} />
      <Route exact path="/balloonpop" element={<Balloonpop />} />
      <Route exact path="/bemillionaire" element={<Bemillionaire />} />
      <Route exact path="/Bottleclimbing" element={<Bottleclimbing />} />
      <Route exact path="/Bottlegarbage" element={<Bottlegarbage />} />
      <Route exact path="/Bottlejump" element={<Bottlejump />} />
      <Route exact path="/Brainexcercisegame" element={<Brainexcercisegame />} />
      <Route exact path="/Burningballs" element={<Burningballs />} />
      <Route exact path="/Carbreaker" element={<Carbreaker />} />
      <Route exact path="/Chickenshooter" element={<Chickenshooter />} />
      <Route exact path="/Choosetruecolors" element={<Choosetruecolors />} />
      <Route exact path="/Christmasgift" element={<Christmasgift />} />
      <Route exact path="/Christmasholiday" element={<Christmasholiday />} />
      <Route exact path="/Christmassantarun" element={<Christmassantarun />} />
      <Route exact path="/Coinsnake" element={<Coinsnake />} />
      <Route exact path="/Collectthesameballs" element={<Collectthesameballs />} />
      <Route exact path="/Colorjumpgame" element={<Colorjumpgame />} />
      <Route exact path="/Colormatch" element={<Colormatch />} />
      <Route exact path="/Cookiesboockies" element={<Cookiesboockies />} />
      <Route exact path="/Cricketbatter" element={<Cricketbatter />} />
      <Route exact path="/Crossyroad" element={<Crossyroad />} />
      <Route exact path="/Cutedressup" element={<Cutedressup />} />
      <Route exact path="/Darkforest" element={<Darkforest />} />
      <Route exact path="/Darkknightrider" element={<Darkknightrider />} />
      <Route exact path="/Dashcolorswitch" element={<Dashcolorswitch />} />
      <Route exact path="/Dashingdonut" element={<Dashingdonut />} />
      <Route exact path="/Diamondrobber" element={<Diamondrobber />} />
      <Route exact path="/Dragonfish" element={<Dragonfish />} />
      <Route exact path="/Drivesafe" element={<Drivesafe />} />
      <Route exact path="/Droptheappleintomouth" element={<Droptheappleintomouth />} />
      <Route exact path="/Duckhunt" element={<Duckhunt />} />
      <Route exact path="/Eggbucketmaster" element={<Eggbucketmaster />} />
      <Route exact path="/Eggcatcher" element={<Eggcatcher />} />
      <Route exact path="/Endlessninjarun" element={<Endlessninjarun />} />
      <Route exact path="/Fighterjet" element={<Fighterjet />} />
      <Route exact path="/Fillthedot" element={<Fillthedot />} />
      <Route exact path="/Firealarmresquers" element={<Firealarmresquers />} />
      <Route exact path="/Fishcatcher" element={<Fishcatcher />} />
      <Route exact path="/Flowershooter" element={<Flowershooter />} />
      <Route exact path="/Flyingtriangle" element={<Flyingtriangle />} />
      <Route exact path="/Goalpostchallenge" element={<Goalpostchallenge />} />
      <Route exact path="/Golfpost" element={<Golfpost />} />
      <Route exact path="/Gopanda" element={<Gopanda />} />
      <Route exact path="/Halloweenhooks" element={<Halloweenhooks />} />
      <Route exact path="/Halloweenrun" element={<Halloweenrun />} />
      <Route exact path="/Happymood" element={<Happymood />} />
      <Route exact path="/Harvestveggie" element={<Harvestveggie />} />
      <Route exact path="/Heroboxjumping" element={<Heroboxjumping />} />
      <Route exact path="/Highwayhero" element={<Highwayhero />} />
      <Route exact path="/Icecandy" element={<Icecandy />} />
      <Route exact path="/Icymatch" element={<Icymatch />} />
      <Route exact path="/Jellybellyjam" element={<Jellybellyjam />} />
      <Route exact path="/Jellyworld" element={<Jellyworld />} />
      <Route exact path="/Jewelinthrowchallenge" element={<Jewelinthrowchallenge />} />
      <Route exact path="/Joytoytrain" element={<Joytoytrain />} />
      <Route exact path="/Jumpandcollectcoins" element={<Jumpandcollectcoins />} />
      <Route exact path="/Lemonjuicer" element={<Lemonjuicer />} />
      <Route exact path="/Ludomultiplayer" element={<Ludomultiplayer />} />
      <Route exact path="/Makeabridgeandgogetdiamond" element={<Makeabridgeandgogetdiamond />} />
      <Route exact path="/Mannequinheadjump" element={<Mannequinheadjump />} />
      <Route exact path="/Motoracer" element={<Motoracer />} />
      <Route exact path="/Movingsparrow" element={<Movingsparrow />} />
      <Route exact path="/Mrpingjumpingjoy" element={<Mrpingjumpingjoy />} />
      <Route exact path="/Musicianmagic" element={<Musicianmagic />} />
      <Route exact path="/Nanoraceescape" element={<Nanoraceescape />} />
      <Route exact path="/Noobvspro" element={<Noobvspro />} />
      <Route exact path="/Perfectpoolshot" element={<Perfectpoolshot />} />
      <Route exact path="/Perfectrotare" element={<Perfectrotare />} />
      <Route exact path="/Pixelfiller" element={<Pixelfiller />} />
      <Route exact path="/Pixelninza" element={<Pixelninza />} />
      <Route exact path="/Pocketjumparcade" element={<Pocketjumparcade />} />
      <Route exact path="/Pongsdot" element={<Pongsdot />} />
      <Route exact path="/Potfiller" element={<Potfiller />} />
      <Route exact path="/Protectredindianman" element={<Protectredindianman />} />
      <Route exact path="/Reversespaceriding" element={<Reversespaceriding />} />
      <Route exact path="/Roadrace2d" element={<Roadrace2d />} />
      <Route exact path="/Rocketpilot" element={<Rocketpilot />} />
      <Route exact path="/Rollingcubeadvantures" element={<Rollingcubeadvantures />} />
      <Route exact path="/Romeoswiftracer" element={<Romeoswiftracer />} />
      <Route exact path="/Runningninja" element={<Runningninja />} />
      <Route exact path="/Saveyourpuppies" element={<Saveyourpuppies />} />
      <Route exact path="/Sawsescapes" element={<Sawsescapes />} />
      <Route exact path="/Shoottogiantbats" element={<Shoottogiantbats />} />
      <Route exact path="/Shootzombie" element={<Shootzombie />} />
      <Route exact path="/Spacebattle" element={<Spacebattle />} />
      <Route exact path="/Speedsquarerunner" element={<Speedsquarerunner />} />
      <Route exact path="/Squareescape" element={<Squareescape />} />
      <Route exact path="/Starfallescape" element={<Starfallescape />} />
      <Route exact path="/Stickman" element={<Stickman />} />
      <Route exact path="/Stressreleasingdots" element={<Stressreleasingdots />} />
      <Route exact path="/Supercandy" element={<Supercandy />} />
      <Route exact path="/Tapcounterchallenge" element={<Tapcounterchallenge />} />
      <Route exact path="/Tennisballarcade" element={<Tennisballarcade />} />
      <Route exact path="/Touchthealphabetintheorder" element={<Touchthealphabetintheorder />} />
      <Route exact path="/Towerbalancer" element={<Towerbalancer />} />
      <Route exact path="/Universaladvanture" element={<Universaladvanture />} />
      <Route exact path="/Waroftank" element={<Waroftank />} />
      <Route exact path="/Yummycandies" element={<Yummycandies />} />
      <Route exact path="/Zombieattack" element={<Zombieattack />} />
      <Route exact path="/Zombiehunter" element={<Zombiehunter />} />
      <Route exact path="/Kingdomfight2" element={<Kingdomfight2 />} />
    </Routes>
  );
}

export default App;
