import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Flyingtriangle() {
  document.title = 'Flying Triangle | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Flying Triangle</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/flyingtriangle/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_flyingtriangle.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/flyingtriangle.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Flying Triangle</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Embark on an exhilarating aerial journey with the captivating game of Flying Triangle.<br /><br />
                    Experience the thrill of soaring through vibrant landscapes as you guide your agile triangle through a
                    maze of challenges. This flying game offers an innovative and addictive gameplay experience that keeps
                    you hooked for hours.<br /><br /> Navigate intricate paths, dodge obstacles, and showcase your skills as
                    you pilot the Flying Triangle to new heights. With its dynamic visuals and engaging mechanics, this
                    triangle game takes free games to a whole new level of excitement. Immerse yourself in the joy of flight
                    and challenge your reflexes in this unique gaming adventure.<br /> Explore the skies, conquer obstacles,
                    and enjoy the freedom of flight in the mesmerizing world of Flying Triangle.</p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Flying Triangle game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Triangle flight gameplay</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Thrilling flying adventure</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Dynamic triangle game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Aerial obstacle challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Triangle piloting simulation</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Action-packed flight game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Flying triangle adventure</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Sky exploration game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Aerial obstacle course</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Flying game experience</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Triangle flyer simulator</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">High-flying challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Free triangle game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Skybound triangle journey</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Flyingtriangle;

