import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Angrybubble() {
  document.title = 'Angry Bubble | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play Online - Angry Bubble</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    href="games/angrybubble/" rel="noreferrer">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_angrybubble.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/angrybubble.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Angry Bubble</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p></p>
                  <p>Balloon shooting is an archery game, in which you can shoot balloons of different colors in the air,
                    using a bow and arrow. In this balloon game, you get 5 arrows to shoot the balloons, if the target is
                    missed then you lose one arrow. <br /><br />As the game goes, you get to see many surprise elements like
                    the bomb, chain ball, unlimited arrows, crossed axe etc.<br /><br />Enjoy shooting the balloons in an
                    outdoor environment by playing this archery game. Shooting the big balloons will fetch you less points,
                    whereas shooting the smaller ones will fetch you more points in this arrow shooting game.
                    <br /><br />Blast multiple balloons at a time, by shooting the elements like crossed axe and chain ball
                    in this bow and arrow game offline. Shoot balloons of different sizes and colors by playing this arrow
                    shooting game. Make sure you aim properly before shooting the balloons with the bow and arrow in this
                    game. Score as high as possible by shooting many balloons in this bow and arrow
                    game.<br /><br />Download this archery game and shoot as many balloons as possible.<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Ballon Game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Shoot Game HTML5</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Play Shooting Balloon</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Play Shoot HTML5</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Angry Bubble</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Balloon Shooter</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Shooting PC Game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Archery </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Shoot Ballon - Ome Games</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Angrybubble;
