import Header from './includes/Header';

function ContactUs() {
  return (
    <>
      <div id="__next">
        <Header />
        {/* <section class="my-5">
          <div class="container">
            <div class="d-flex flex-column justify-content-center align-items-center"><img
                src="assets/Game/backgroundtitle.png" alt="Game" draggable="false" class="img-fluid" /></div>
          </div>
        </section> */}
        <section class="my-3 AboutTxt">
          <div class="container">
            <h5 class="HeadBG">Contact US</h5><br />
            <script type='text/javascript'> 
              google_ad_client = "pub-3990886853793852";
              google_ad_host = "pub-2885846345242349"; 
              google_ad_width = 728; 
              google_ad_height = 90; 
              google_ad_type = "text_image"; 
              google_color_border = "FFFFFF"; 
              google_color_bg = "ffffff"; 
              google_color_link = "0000ff"; 
              google_color_text = "000000"; 
              google_color_url = "008000"; 
              google_page_url = "https://heylink.me/"; 
            </script>
            <script src='https://pagead2.googlesyndication.com/pagead/show_ads.js' type='text/javascript'></script>
            <p>contact@omegames.in</p><br />
            <h5 class="HeadBG">Copy Right Issue</h5><br />
            <p>We own the Intellectual Property Rights (IPR) to a large number of the games on omeGames’ sites, and for
              certain games, we requested and obtained licenses from the copyright holders known to us. For some games, we
              could not find any copyright information, or, due to general use on the internet, this information can no
              longer be obtained.<br /><br />Some games may be used under special conditions, considering a number of
              prerequisite constraints. These prerequisite constraints are, as far as we could determine, met by
              omeGames.<br /><br />We do not change anything in the source code of the games. For example, credit
              holders, brand names, or references to websites remain unchanged. If any copyright or other IPR that you may
              have is possibly being infringed by/on omeGames’ sites, please inform us immediately, thereby providing us
              with the following:<br /><br />i) the electronic or physical signature of the owner of the IPR or the person
              authorized to act on the owner&#x27;s behalf;<br /><br />ii) a description of the IP Right that has been
              infringed, and a description of the infringing activity;<br /><br />iii) the location where the unauthorized
              copy of the copyrighted work exists (for example, the URL of the omeGames website where it is posted, or
              the name of the book in which it has been published, or, in case of a registered brand name, an excerpt of
              such register evidencing the registry);<br /><br />iv) a copy of a license in which you are granted the
              right to use and to protect such IPR (if you are not the owner of the IPR);<br /><br />v) the identification
              of the URL or other specific location on this site where the material that you claim is infringing is
              located; this information must be specific enough to enable us to locate such material;<br /><br />vi) your
              name and full contact details; and vii) a statement by you that you have a sincere and honest belief that
              the disputed use is not authorized by the copyright owner, its agent, or the law.<br /><br />Send above
              details at contact@omegames.in. We will review it and will start taking action on it as soon as possible.
              We will reply you on any of the query within 7 days.<br /><br /></p><br />
          </div>
        </section>
      </div>
    </>
  );
}

export default ContactUs;
