import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Balloonpop() {
  document.title = 'Balloon Pop | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Balloon Pop</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/balloonpop/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_balloonpop.png" alt="Game"
                    draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/balloonpop.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Balloon Pop</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Balloon Pop is an exciting and addictive mobile game that challenges players to pop as many balloons as
                    possible before the time runs out. With its colorful graphics, fun gameplay, and simple mechanics,
                    Balloon Pop is a game that&#x27;s easy to pick up but hard to put down.<br /><br />In Balloon Pop,
                    players must pop balloons by tapping on them as they float across the screen. The game features multiple
                    levels with different colors and types of balloons, each with their own unique challenges and
                    rewards.<br /><br />As players progress through the levels, they must pop as many balloons as possible
                    within the time limit, while also avoiding obstacles like bombs and spikes that can cause them to lose a
                    life.<br /><br />Balloon Pop is designed with bright and colorful graphics, creating a fun and engaging
                    gameplay experience. With its simple and intuitive controls, players of all ages can enjoy the game and
                    improve their balloon-popping skills.<br /><br />Whether you&#x27;re looking for a quick and fun mobile
                    game to play during your spare time or just want to challenge yourself to beat your high score, Balloon
                    Pop is a game that&#x27;s sure to provide endless entertainment. With its addictive gameplay, colorful
                    graphics, and fun challenges, it&#x27;s a game that&#x27;s hard to put down.<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH"> Addictive balloon game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Fun popping game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Challenging puzzle game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Exciting balloon adventure game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Kid-friendly balloon game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Family-friendly popping game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Colorful balloon popping game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Multi-level popping game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Fun and entertaining balloon game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Easy-to-play balloon pop game. </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Balloonpop;
