import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Colormatch() {
  document.title = 'Color Match | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Color Match</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/colormatch/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_colormatch.png" alt="Game"
                    draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/colormatch.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Color Match</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Color Match is a fun and addictive mobile game that challenges players to match colors and
                    shapes.<br /><br />In Color Match, players must swipe left, right, up, or down to match the colors and
                    shapes of falling objects. The game features intuitive touch controls, allowing players to easily swipe
                    and match their colors.<br /><br />The game features a variety of power-ups and bonuses, which players
                    can use to enhance their color-matching abilities and score even more points. As players progress
                    through the game, the levels become more challenging, with faster falling objects and more complex
                    patterns to match.<br /><br />Color Match is designed with vibrant and colorful graphics, creating a
                    visually appealing and engaging gameplay experience. With its simple mechanics and addictive gameplay,
                    players can enjoy the game for minutes or hours at a time.<br /><br />Whether you&#x27;re a fan of
                    puzzle games or just looking for a fun and challenging mobile game to play, Color Match is a game
                    that&#x27;s sure to provide endless entertainment. With its intuitive controls, beautiful graphics, and
                    challenging gameplay, it&#x27;s a game that&#x27;s hard to put down.<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH"> Fun and colorful matching game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Exciting puzzle game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Challenging color adventure game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Addictive matching game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Thrilling color collecting game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Kid-friendly color game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Family-friendly matching game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Multi-level color matching game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Easy-to-play color matching game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Entertaining and engaging color game for all ages. </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Colormatch;

