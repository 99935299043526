import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Fighterjet() {
  document.title = 'Figher Jet | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Fighter Jet</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/fighterjet/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_fighterjet.png" alt="Game"
                    draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/fighterjet.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Fighter Jet</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Jet Strike Mission is an ultimate and modern jet fighter game which allows you to ride a aircraft
                    simulator on an endless runway. Get ready for real breathtaking action while playing this fighter
                    aircraft simulation game. Unlike other jets fighter games, the realistic experience of air craft flight
                    simulation. The gameplay is full of action and jet strike fighting.<br /><br />- For best game
                    experience<br /><br />- Use joystick for up/down and left/right control<br /><br />- Use separate fire
                    buttons for missile and bullet firing<br /><br />- Real sounds of roaring fighter
                    aircrafts.<br /><br />- Easy and smooth controls &amp; realistic navigation.<br /><br />- Realistic and
                    smooth aircraft physics.<br /><br />- Amazing and Marvelous environment.<br /><br />- Camouflage and
                    Flag based Jets model.<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Jet Fighter Game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Fighter Game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Shoot Enemy</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Fighter Enemy</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Shoot plan</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Fighter plan</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Fighter Pilot</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Fight plan</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Play flighter jet</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Jet Fight</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Fighterjet;

