import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Towerbalancer() {
  document.title = 'Tower Balancer | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Tower Balancer</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/towerbalancer/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_towerbalancer.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/towerbalancer.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Tower Balancer</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>With &quot;Tower Balancer,&quot; the ultimate tower game of 2023, set out on a thrilling adventure of
                    accuracy and strategy. Test your abilities in this addictive tower balancing game that tests your
                    capacity to build and maintain tall structures.<br /><br />Play through exhilarating levels as you
                    carefully arrange blocks to build skyscrapers that get more and more complicated. Your allies as you
                    balance your way to the top are strategic thought and a steady hand. With its distinctive fusion of
                    thrill and mental sharpness, &quot;Tower Balancer&quot; delivers hours of fun gameplay.<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Tower Balancer game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Balancing tower challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Tower building gameplay</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Tower stacking game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Skillful tower balancing</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Physics-based tower game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Tower construction challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Tower stability game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Architectural balancing fun</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Precision tower stacking</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Balance and build game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Gravity-defying tower challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Physics puzzle tower game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Tower stability simulation</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Tower architecture adventure</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Towerbalancer;

