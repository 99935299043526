import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Sawsescapes() {
  document.title = 'Saw Escapes | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Saw Escapes</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/sawsescapes/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_sawsescapes.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/sawsescapes.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Saw Escapes</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Prepare for a heart-pounding escape game that will put your brains and reflexes to the
                    test.<br /><br /> You find yourself in the dangerous realm of Saws Escape, entangled in a maze of lethal
                    saws.<br /><br /> Your objective? Find a way to freedom by avoiding the perilous traps and avoiding the
                    spinning blades.<br /><br /> Saws Escape provides an exciting experience that will have you on the edge
                    of your seat with its heart-pounding action and simple controls. Will you accept the challenge and find
                    your way out of the complex saw maze? In this challenging escape game that mixes strategy, timing, and
                    fast thinking, dare to test yourself. Start a quest to demonstrate your escape artistry by downloading
                    Saws Escape right away!<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Saws Escape game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Escape room challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Thrilling escape adventure</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Puzzle-solving escape game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Deadly traps and saws</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Maze of danger game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Intense survival challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Escape the saws puzzle</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Heart-pounding escape gameplay</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Strategy and reflexes game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Maze escape adventure</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Challenging trap evasion</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Escape artist simulation</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Survival puzzle challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Conquer the saws challenge</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Sawsescapes;

