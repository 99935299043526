import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Jewelinthrowchallenge() {
  document.title = 'Jewel In Throw Challenge | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Jewel In Throw Challenge</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/jewelinthrowchallenge/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_jewelinthrowchallenge.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/jewelinthrowchallenge.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Jewel In Throw
                        Challenge</span><span class="cl-peta d-flex justify-content-start align-items-center"><img
                          src="assets/Game/watch.png" alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Get lost in the exciting and brilliant world of diamonds.<br /><br /> You&#x27;ll encounter the
                    pinnacle of precision and strategy in this engaging &quot;Jewel In Throw&quot; game.<br /><br /> Your
                    aim? to deftly throw the Arroa, a mystical gem, and guide it past tricky challenges, all the while
                    gathering precious gems.<br /><br /> Play an addictive game that requires timing and accuracy while
                    exploring colorful vistas brimming with mystery and awe. Get ready to be mesmerized by the mix of visual
                    beauty and exciting challenges. Are you capable of perfecting the Arroa throw in order to acquire every
                    jewel? Take off on this glittering voyage right away to feel the throw&#x27;s pleasure like never
                    before.<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Jewel In Throw Challenge game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Gem-throwing adventure</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Precision and strategy game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Arroa throwing challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Gem collection gameplay</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Jewel navigation puzzle</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Throw accuracy game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Jewel collecting challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Addictive throwing adventure</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Dynamic obstacle course game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Gem-throwing strategy</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Arroa precision game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Gem-tossing challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Obstacle navigation game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Jewel-based puzzle adventure</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Jewelinthrowchallenge;

