import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Shootzombie() {
  document.title = 'Shoot Zombie | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Shoot Zombie</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/shootzombie/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_shootzombie.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/shootzombie.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Shoot Zombie</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Discover the adrenaline-pumping action of &quot;Shoot Zombie,&quot; the ultimate shooting game that
                    will put your bravery and accuracy to the test. Prepare to join an undead-infested world in this
                    riveting zombie game. Armed with a variety of potent weapons, go on a challenging zombie hunt while
                    navigating spooky settings.<br /><br />Take part in a high-stakes hunting game where your shooting and
                    survival skills will be tested to the utmost. As you battle countless waves of hungry zombies, aim for
                    the head, plan your movements, and be watchful. The spine-tingling challenge of &quot;Shoot Zombie&quot;
                    will have you on the edge of your seat.<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Shoot Zombie game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Zombie shooting gameplay</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Zombie shooter challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Zombie hunting game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Undead shooting experience</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Zombie apocalypse game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Shooter against zombies</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Zombie survival shooter</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Zombie-infested battleground</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Zombie slaying action</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">First-person zombie shooter</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Zombie target practice</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Zombie shooter simulation</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Zombie horde combat</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Intense zombie shooting frenzy</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Shootzombie;

