import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Fillthedot() {
  document.title = 'Fill The Dot | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Fill The Dot</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/fillthedot/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_fillthedot.png" alt="Game"
                    draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/fillthedot.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Fill The Dot</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Play &quot;Fill the Dot,&quot; a compelling dot challenge game, to exercise your brain and reflexes
                    while putting your timing and accuracy to the test. Enter a dynamic, ever-changing universe where you
                    must carefully direct dots to fill in predefined locations.<br /><br />Your goal in this game of dots is
                    to carefully move through each level, making sure that each dot lands in the ideal location. The
                    difficulties get trickier as you advance, necessitating quick thinking and
                    movements.<br /><br />&quot;Fill the Dot&quot; is appropriate for players of all ages because it
                    provides a special synthesis of entertainment and mental agility. This game promises unlimited
                    dot-filling pleasure, whether you&#x27;re a casual gamer looking for a fast challenge or a committed
                    player aiming for perfection.<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Fill the Dot game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Dot challenge gameplay</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Precision dot filling</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Filling dots game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Strategic dot navigation</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Mind-bending dot challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Dot placement puzzle</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Reflex-based dot game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Filling dots simulation</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Strategic dot-filling challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Challenging dot puzzle</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Mind-teasing dot game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Fill the spaces with dots</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Filling dots strategy</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Addictive dot challenge</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Fillthedot;

