import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Golfpost() {
  document.title = 'Golf Post | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play Online - Golf Post</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    href="games/golfpost/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_golfpost.png" alt="Game"
                    draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/golfpost.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Golf Post</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Pinpoint is a game-changing strokes gained golf tracker, featuring advanced statistics so you can
                    improve your golf game fast. Input your shots with our easy-to-use GPS tracker on any golf course around
                    the world. Then measure your performance against PGA pros, get precise stats and gain insights across
                    all areas of your golf game. Learn exactly where to focus your practice sessions to gain strokes and
                    shoot lower scores.</p>
                  <h5> STOP TRACKING USELESS GOLF STATS</h5>
                  <p> Other golf apps and rangefinders are tracking stats that won’t actually help you get better Pinpoint
                    is based on &quot;strokes gained,&quot; an advanced golf statistic compiled from details of the strokes
                    taken by pros in PGA tournaments &quot;Strokes gained&quot; precisely measures the details of each shot,
                    then compares the abilities of different players across all areas of the game</p>
                  <h5> BREAK THROUGH YOUR PLATEAU</h5>
                  <p> Understanding strokes gained helps you focus your practice where it counts Pinpoint provides advanced
                    statistics to identify areas for improvement and track your progress over time</p>
                  <h5> EASILY TRACK YOUR GOLF ROUNDS</h5>
                  <p> Use Pinpoint while you play or after the round is over - Track each shot accurately and reliably with
                    GPS fine-tuning for all parts of your game: tee shots, approach shots, short game and
                    putting<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Golf Game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Golf - Ome Games</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Golf HTML5 Game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Online Golf Game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Play Golf</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Golf Game for Kids</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Golf Sport</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Golf Course</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Golf PC Game</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Golfpost;

