import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Halloweenhooks() {
  document.title = 'Halloween Hooks | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Halloween Hooks</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    href="games/halloweenhooks/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_halloweenhooks.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/halloweenhooks.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Halloween Hooks</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Play Halloween Hooks Game for free now on omeGames. Halloween Hooks is available to play for
                    free.<br /><br />Solve puzzles and brain teasers in this mystery adventure! Search for missing objects!
                    Your target is to save the world from dark magic!<br /><br />Halloween Hooks is playable online as an
                    HTML5 game, therefore no download is necessary.<br /><br />Play puzzles and brain teasers in these
                    halloween games! Enjoy the search and find in hidden object mystery games! Your target is to seek the
                    needed things to stop the mysteries in your mansion.<br /><br />The Halloween Wright family story - pass
                    item to left and right. ... Halloween Charades: Printable Halloween Game - The Joys of
                    Boys.<br /><br />Enjoy our new horror halloween games! This autumn night has always been our favorite
                    day to scare each other. There are many halloween quest to complete in these “hidden object - adventure
                    games”. At night, the two girls were completely alone at home. Little sister asked the older one to read
                    mystery tales from her favorite “black book” for her at night. As soon as they began to read it, a lot
                    of terrible monsters and spirit were released into their manor. One of these creatures kidnapped a
                    little girl. Will the eldest be able to save her from this mystical nightmare. She will have to solve
                    many strange puzzles, as well as find hidden objects. Can you defeat all the scary monsters in this
                    nightmare?<br /><br />Halloween Hooks is fun and full of adventures.<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Halloween Party Games</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Halloween Stories</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Ghost Story Games</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Halloween Games</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Horror Games</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Party Games</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Halloween Hooks and Game</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Halloweenhooks;

