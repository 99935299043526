import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Squareescape() {
  document.title = 'Square Escape | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Square Escape</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/squareescape/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_squareescape.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/squareescape.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Square Escape</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>With &quot;Square Escape,&quot; the ultimate escape challenge game that will capture your attention and
                    put your problem-solving abilities to the test, set out on a fascinating adventure! You&#x27;ll be
                    immersed in a universe of squares and puzzles in this intriguing game, where every move
                    counts.<br /><br />Make your way through a series of puzzles with a square theme by using your cunning
                    to understand hints, crack riddles, and reach new levels. &quot;Square Escape&quot; is the ideal game
                    for players of all ages because it combines strategy and logical thinking in an engaging
                    way.<br /><br />Play an escape game that offers countless hours of amusement and intellectual
                    stimulation. This game offers an immersive experience whether you&#x27;re a puzzle lover or a casual
                    gamer looking for an interesting adventure.<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH">Square Escape game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Escape challenge gameplay</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Puzzle-solving adventure</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Square-themed escape</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Logic and strategy game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Square puzzle challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Brain-teasing escape game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Addictive escape challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Square escape room</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Mind-bending puzzle game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Free escape game</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Square maze adventure</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Square puzzle-solving</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Escape room challenge</h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH">Strategy and logic game</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Squareescape;

