import { useEffect, useState } from 'react';
import Header from '../includes/Header';
import axios from 'axios';
import { GAME_LIST } from '../../Apis';
import { Link, useLocation } from 'react-router-dom';

function Christmasholiday() {
  document.title = 'Christmas Holiday | omeGames';
    const [games, setGames] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  async function fetchData() {
    let data = await axios({
      method: 'GET',
      mode: 'cors',
      url: GAME_LIST
    });
    setGames(data.data.data);
  }
  useEffect(() => {
    fetchData();
  }, []);


  const [visibleGames, setVisibleGames] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const newGames = games.slice(visibleGames.length, visibleGames.length + 12); // Load 10 games at a time
          setVisibleGames(prevGames => [...prevGames, ...newGames]);
          observer.unobserve(entry.target);
        }
      });
    });

    const sentinel = document.querySelector('#sentinel');
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [visibleGames]);
  return (
    <>
      <div id="__next">
        <Header />
        <div>
          <section class="my-5">
            <div class="container">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4>Play online - Christmas Holiday</h4><br /><br />
                <div class="gameArea d-flex justify-content-center align-items-center w-100 position-relative"><a
                    rel="noreferrer" href="games/christmasholiday/">
                    <div class="PlayBtn" id="playBtn">Play Game</div>
                  </a>
                  <div class="blackLayer" id="playBtn"></div><img src="assets/Game/banners/banner_christmasholiday.png"
                    alt="Game" draggable="false" class="img-fluid bannerImg" />
                </div>
                <div
                  class="w-100 optiomsBar d-flex flex-column flex-lg-row justify-content-start justify-content-lg-between align-items-center p-3 cusOver">
                  <div
                    class="d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center mb-4 mb-lg-0">
                    <img src="assets/Game/logos/christmasholiday.png" alt="Online Game" draggable="flase"
                      class="rounded-3 barImg" />
                    <div class="ms-2 d-flex flex-column "><span class="cl-b fw-bold f-20 ">Christmas Holiday</span><span
                        class="cl-peta d-flex justify-content-start align-items-center"><img src="assets/Game/watch.png"
                          alt="watchlist icon" draggable="false" class="me-1" />50k</span></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <p>Christmas Holiday is a festive mobile game that captures the spirit of the holiday season with its
                    cheerful graphics, joyful music, and fun gameplay. In this game, players can experience the magic of
                    Christmas as they help Santa Claus prepare for the big day.<br /><br />In Christmas Holiday, players
                    take on the role of Santa&#x27;s helper and must complete a variety of tasks and challenges to ensure
                    that everything is ready for Christmas. They must decorate the Christmas tree, wrap presents, bake
                    cookies, and even help Santa deliver presents to children all over the world.<br /><br />The game
                    features intuitive touch controls, allowing players to easily navigate through the different tasks and
                    challenges. Players must also strategically use power-ups and other special items to help them complete
                    their tasks and earn rewards.<br /><br />Christmas Holiday is designed with colorful and cheerful
                    graphics, creating a playful and engaging gameplay experience. With its festive music and sound effects,
                    it&#x27;s a game that&#x27;s sure to get you into the holiday spirit.<br /><br />Whether you&#x27;re a
                    fan of Christmas or just looking for a fun and festive mobile game to play, Christmas Holiday is a game
                    that&#x27;s sure to put a smile on your face. With its joyful gameplay, challenging tasks, and festive
                    atmosphere, it&#x27;s a game that&#x27;s perfect for the holiday season.<br /><br /></p>
                  <div class="d-flex flex-wrap">
                    <div class="cusBadge">
                      <h6 class="cusH"> Festive holiday game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Fun Christmas game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Addictive winter game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Challenging holiday puzzle game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Jolly holiday game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Kid-friendly Christmas game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Family-friendly holiday game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Exciting holiday adventure game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Multi-level Christmas game </h6>
                    </div>
                    <div class="cusBadge">
                      <h6 class="cusH"> Entertaining winter-themed game. </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-3">
            <div className="container">
              <div className="adsenseDiv">
                <ins className="adsbygoogle" style={{ display: 'block' }} data-ad-format="auto"
                  data-ad-client="ca-pub-3112818294798377" data-ad-slot="8642117244"
                  data-full-width-responsive="true"></ins>
              </div>
              <div className="row">
                {
                  visibleGames.map((game, index) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-2 m-0 p-0 p-2" key={index}>
                        <Link to={`/${game.url}`}>
                          <div className="MainHVR d-flex justfy-content-center align-items-center">
                            <div className="DataName">{game.name}</div>
                            <div className="databg"></div>
                            <img src={`assets/Game/logos/${game.image}`} alt="Game Images" draggable="false" className="img-fluid HvrIMG mx-auto" loading="lazy" />
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </div>
              <div id="sentinel" className="col-12"></div> {/* Sentinel element for IntersectionObserver */}
            </div>
          </section>

        </div>
      </div>
    </>
  );
}

export default Christmasholiday;
